import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import API from "../../api/API";
import CategoryForm from "../../components/forms/CategoryForm/CategoryForm";
import { useCategoryFormik } from "../../components/forms/CategoryForm/lib/useCategoryFormik";
import HandleLoading from "../../components/shared/HandleLoading/HandleLoading";
import HandleError from "../../components/shared/HandleError/HandleError";
import FormLayout from "../../layouts/PageLayout";
import { useRouter } from "../../lib/hooks/useRouter";
import { useLanguageContext } from "../../lib/context/LanguageContext/LanguageContext";
import FormSkeleton from "../../components/forms/Form.skeleton";

export const EditCategory = (props) => {
  const router = useRouter();
  const { getLngPrefix } = useLanguageContext();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [initialValues, setInitialValues] = useState({});
  const { t, i18n } = useTranslation();
  const { id } = props.match.params;

  const formik = useCategoryFormik({
    initialValues,
    onSubmit: async (values, formikHelpers) => {
      let formData = new FormData();
      formData.append("name", values.name);
      formData.append("description", values.description);
      formData.append("categoryImage", values.img);

      try {
        await API.category.updateCategory(id, formData);
        formikHelpers.resetForm({ values: { ...values } });
        router.history.push(`${getLngPrefix()}/categories`);
      } catch (e) {}
    },
  });

  useEffect(() => {
    const getCategory = async () => {
      try {
        setLoading(true);

        const category = await API.category.getCategory(id);

        setInitialValues({
          name: category.category.name,
          description: category.category.description,
          img: category.categoryImage, //fix this
          translations: category.translations,
        });
      } catch (error) {
        setError(error.toString());
      } finally {
        setLoading(false);
      }
    };

    getCategory();
  }, [id, i18n.language]);

  return (
    <HandleLoading loading={loading} loadingPlaceholder={<FormSkeleton />}>
      <HandleError error={error}>
        <FormLayout title={t("editCategory")}>
          <CategoryForm formik={formik} editMode />
        </FormLayout>
      </HandleError>
    </HandleLoading>
  );
};
