import React from "react";
import { Prompt } from "react-router";
import injectSheet from "react-jss";
import PropTypes from "prop-types";
import { useUIContext } from "../../../lib/context/UIContext/UIContext";
import Input from "../../shared/Input/Input";
import Image from "../../shared/Image/Image";
import Button from "../../shared/Button/Button";
import { useTranslation } from "react-i18next";
import FileInput from "../../shared/FileInput/FileInput";
import TranslationReminder from "../../shared/TranslationReminder/TranslationReminder";
import FieldVersionInOtherLang from "../../shared/FieldVersionInOtherLang/FieldVersionInOtherLang";

const OfferForm = (props) => {
  const { classes, formik, editMode } = props;
  const { isDesktop } = useUIContext();
  const { t } = useTranslation();

  // useEffect(() => {
  //   window.onbeforeunload = (e) => {
  //     console.log("asd");
  //     return "asd";
  //   };
  // });

  return (
		<form onSubmit={formik.handleSubmit} className={classes.root}>
			<Prompt when={formik.dirty} message={t("onLeaveMessage")} />
			<div className={classes.fieldGroup}>
				{editMode && (
					<TranslationReminder
						translations={formik.values.translations}
					/>
				)}
				{editMode && (
					<FieldVersionInOtherLang
						translations={formik.values.translations}
						field="title"
					/>
				)}
				<Input
					name="title"
					label={t("title")}
					id="title"
					value={formik.values.title}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					error={!!formik.errors.title && formik.touched.title}
					helperText={
						formik.errors.title &&
						formik.touched.title &&
						formik.errors.title
					}
				/>
				{editMode && (
					<FieldVersionInOtherLang
						translations={formik.values.translations}
						field="content"
					/>
				)}
				<Input
					name="content"
					label={t("content")}
					id="content"
					value={formik.values.content}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					error={!!formik.errors.content && formik.touched.content}
					helperText={
						formik.errors.content &&
						formik.touched.content &&
						formik.errors.content
					}
				/>
				<FileInput
					onChange={(file) => formik.setFieldValue("img", file)}
					onBlur={formik.handleBlur}
					error={
						formik.errors.img &&
						formik.touched.img &&
						formik.errors.img
					}
				/>
				<div className={classes.priceField}>
					<Input
						name="price"
						label={t("price")}
						id="price"
						value={formik.values.price}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						error={!!formik.errors.price && formik.touched.price}
						helperText={
							formik.errors.price &&
							formik.touched.price &&
							formik.errors.price
						}
					/>
				</div>
				{!isDesktop && (
					<Image
						src={formik.values.img}
						alt="Offer Image"
						style={{
							objectFit: "cover",
							width: "100%",
							height: "150px",
							marginBottom: "30px",
						}}
					/>
				)}
				<Button
					type="submit"
					label={editMode ? t("edit") : t("addOffer")}
					disabled={!formik.dirty}
					loading={formik.isSubmitting}
				/>
			</div>
			{isDesktop && (
				<Image
					src={formik.values.img}
					alt="Offer Image"
					style={{
						objectFit: "cover",
						width: "250px",
						height: "250px",
					}}
				/>
			)}
		</form>
  );
};

const styles = {
  root: {
    display: "flex",
    justifyContent: "space-between",
    "@media (max-width:768px)": {
      flexDirection: "column",
    },
  },
  fieldGroup: {
    width: "900px",
    marginRight: "20px",
    "@media (max-width:768px)": {
      width: "100%",
    },
    "& > *": {
      marginBottom: "40px",
    },
    "& :last-child": {
      marginBottom: 0,
    },
  },
  priceField: {
    width: "80px",
  },
};
export default injectSheet(styles)(OfferForm);

OfferForm.propTypes = {
  classes: PropTypes.object,
  formik: PropTypes.object.isRequired,
};
