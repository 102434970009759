import React from "react";
import OffersTable from "../../components/tables/OffersTable/OffersTable";

export const Offers = () => {
  return (
    <div>
      <OffersTable />
    </div>
  );
};
