import React, { useRef, useState } from "react";
import injectSheet from "react-jss";
import cs from "classnames";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Icon } from "../Icon/Icon";

const FileInput = (props) => {
  const { classes, error, onChange, onBlur } = props;
  const inputRef = useRef(null);
  const { t } = useTranslation();
  const [fileName, setFileName] = useState("");

  const handleChange = (e) => {
    if (e.currentTarget.files[0] === undefined) return; //Do nothing if cancel button is pressed while choosing image

    setFileName(e.currentTarget.files[0]?.name);
    onChange(e.currentTarget.files[0]);
  };

  const clearImage = () => {
    setFileName("");
    inputRef.current.value = "";
    onChange(undefined);
  };

  return (
    <div>
      <label className={cs(classes.root, fileName && classes.fileChosenBorder)}>
        <input
          ref={inputRef}
          type="file"
          id="input"
          className={classes.input}
          onChange={handleChange}
          onBlur={onBlur}
        />
        <span role="button" className={classes.button}>
          {t("chooseImage")}
        </span>
        <span
          className={cs(classes.label, fileName && classes.fileChosenLabel)}
        >
          {fileName || t("noImageChosen")}
        </span>
        <span className={classes.error}>{error}</span>

        {fileName && (
          <Icon icon="clear" className={classes.icon} onClick={clearImage} />
        )}
      </label>
    </div>
  );
};

const styles = ({ palette }) => ({
  root: {
    position: "relative",
    paddingRight: "30px",
    background: palette.activeItem,
    borderRadius: "8px 8px 0 0",
    cursor: "pointer",
    width: "300px",
    height: "55px",
    display: "flex",
    alignItems: "center",
    padding: "0 10px",
    boxShadow: "inset 0 -1px 0 #7B8C92",
    "@media(max-width:768px)": {
      width: "100%",
    },
  },
  fileChosenBorder: {
    boxShadow: "inset 0 -2px 0 #43BCF2", //Thicker blue border and then file is selected.
  },

  input: { display: "none" },

  button: {
    backgroundColor: palette.button.normal,
    borderRadius: "5px",
    color: palette.common.white,
    padding: "5px 10px",
    whiteSpace: "pre",
  },

  label: { marginLeft: "10px", fontSize: "0.95rem", opacity: 0.6 },

  icon: {
    cursor: "pointer",
    position: "absolute",
    right: 10,
    top: 15,
    opacity: 0.5,
    "&:hover": {
      opacity: 1,
    },
  },

  fileChosenLabel: { opacity: 1 }, //Make label opacity 100% when file is selected.

  error: {
    position: "absolute",
    color: palette.button.danger,
    fontSize: "0.9rem",
    marginLeft: "12px",
    width: "100%",
    top: 60,
    left: 0,
    display: "inline-block",
  },
});

export default injectSheet(styles)(FileInput);

FileInput.propTypes = {
  classes: PropTypes.object,
  error: PropTypes.string,
  onChange: PropTypes.func,
};
