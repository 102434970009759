import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
export const useCategoryFormik = (props) => {
  const { t } = useTranslation();

  const categorySchema = Yup.object().shape({
    name: Yup.string().required(t("formValidations.name")),
    description: Yup.string().required(t("formValidations.description")),
    img: Yup.string().required(t("formValidations.image")),
    id: Yup.string().required(t("formValidations.id")),
  });

  return useFormik({
    initialValues: {
      name: "",
      description: "",
      img: "",
      id: "",
      ...props.initialValues,
    },
    enableReinitialize: true,
    validationSchema: categorySchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values, formikHelpers) => {
      await props.onSubmit(values, formikHelpers);
    },
  });
};

useCategoryFormik.propTypes = {
  props: PropTypes.shape({
    initialValues: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
  }),
};
