import React from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import PageLayout from "../../layouts/PageLayout";
import SmsTable from "../../components/tables/SmsTable/SmsTable";
import NotificationsTable from "../../components/tables/NotificationsTable/NotificationsTable";
import ActivateUserForm from "../../components/forms/ActivateUserForm/ActivateUserForm";
import { VerificationCodeTable } from "../../components/tables/VerificationCodeTable/VerificationCodeTable";
import { useLanguageContext } from "../../lib/context/LanguageContext/LanguageContext";
import StyledLink from "../../components/shared/StyledLink/StyledLink";
import { useTranslation } from "react-i18next";

const Logs = () => {
  const { getLngPrefix } = useLanguageContext();
  const { t } = useTranslation();
  return (
		<PageLayout title="Logs">
			<div
				style={{
					marginBottom: "30px",
					display: "flex",
					flexWrap: "nowrap",
				}}
			>
				<StyledLink label="SMS" to={`${getLngPrefix()}/logs/sms`} />
				<StyledLink
					label={t("verificationCodes")}
					to={`${getLngPrefix()}/logs/verification-codes`}
				/>
				<StyledLink
					label={t("notifications")}
					to={`${getLngPrefix()}/logs/notifications`}
				/>
				<StyledLink
					label={t("activateUser")}
					to={`${getLngPrefix()}/logs/activate-user`}
				/>
			</div>

			<Switch>
				<Route
					exact
					path={`${getLngPrefix()}/logs/sms`}
					component={SmsTable}
				/>
				<Route
					exact
					path={`${getLngPrefix()}/logs/verification-codes`}
					component={VerificationCodeTable}
				/>
				<Route
					exact
					path={`${getLngPrefix()}/logs/notifications`}
					component={NotificationsTable}
				/>
				<Route
					exact
					path={`${getLngPrefix()}/logs/activate-user`}
					component={ActivateUserForm}
				/>

				<Route
					render={() => (
						<Redirect to={`${getLngPrefix()}/logs/sms`} />
					)}
				/>
			</Switch>
		</PageLayout>
  );
};

export default Logs;
