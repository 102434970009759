import React, { useEffect, useRef, useState } from "react";
import injectSheet from "react-jss";
import cs from "classnames";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Icon } from "../shared/Icon/Icon";
import { useUIContext } from "../../lib/context/UIContext/UIContext";
import UserInfo from "./UserInfo";
import { useAuthContext } from "../../lib/context/AuthContext/AuthContextProvider";
import { useOutsideClickDetection } from "../../lib/hooks/useOutsideClickDetection";
import LanguageSwitcher from "../LanguageSwitcher/LanguageSwitcher";

const styles = ({ size, palette, transitions, boxShadow }) => ({
  root: {
    margin: `0 -${size.spacing - 2}px`,
    paddingLeft: size.spacing,
    paddingRight: size.spacing,
    height: size.header.height,
    display: "flex",
    position: "sticky",
    top: "0",
    background: palette.leadColor,
    alignItems: "center",
    zIndex: "5",
    "@media (max-width:768px)": {
      width: "100vw",
      height: "90px",
      marginLeft: "-20px",
      padding: "0 20px",
    },
  },
  icon: {
    cursor: "pointer",
    color: palette.secondaryTextColor,
    height: size.header.iconSize,
    width: size.header.iconSize,
    transition: transitions.common,
    position: "relative",
  },
  logoutIcon: {
    marginRight: "5px",
  },

  rotateIcon: {
    transform: "rotate(180deg)",
  },

  ddList: {
    margin: 0,
    padding: 0,
    flexDirection: "column",
    background: palette.common.white,
    boxShadow: boxShadow.common,
    position: "fixed",
    right: size.spacing + 5,
    width: "212px",
    listStyle: "none",
    borderRadius: "0 0 8px 8px",
    zIndex: "4",
    transition: transitions.common,
    overflow: "hidden",

    "& > li": {
      minHeight: "50px",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      paddingLeft: "15px",
      "&:hover": {
        background: "#ecedef",
      },
    },

    "@media (max-width:768px)": {
      right: size.spacing - 20,
    },
  },
  ddShow: {
    transform: "translateY(0)",
  },
  ddHide: {
    transform: "translateY(-240px)",
  },
});

const Header = (props) => {
  const { sidebarOpen, setSidebarOpen } = useUIContext();
  const { isDesktop, setIsDesktop } = useUIContext();
  const { logout } = useAuthContext();
  const [ddOpen, setDdOpen] = useState(false);
  const { t } = useTranslation();
  const userRef = useRef(null);
  const { classes } = props;

  const toggleDd = () => {
    setDdOpen((prev) => !prev);
  };

  const updateMedia = () => {
    setIsDesktop(window.innerWidth > 1100);
  };

  const handleClickOutside = () => {
    setDdOpen(false);
  };
  // useOutsideClickDetection(ddRef, handleClickOutside);
  useOutsideClickDetection(userRef, handleClickOutside);

  //Event listener for window size
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  //Check window size to decide whether sidebar should be opened or not
  useEffect(() => {
    isDesktop ? setSidebarOpen(true) : setSidebarOpen(false);
  }, [setSidebarOpen, isDesktop]);

  return (
    <div ref={userRef}>
      <div className={classes.root}>
        <div onClick={() => setSidebarOpen((prev) => !prev)}>
          <Icon
            icon="menu_open"
            className={cs(classes.icon, !sidebarOpen && classes.rotateIcon)}
          />
        </div>
        <LanguageSwitcher />
        <UserInfo onClick={toggleDd} ddOpen={ddOpen} />
      </div>
      <ul
        className={cs(classes.ddList, ddOpen ? classes.ddShow : classes.ddHide)}
        ref={userRef}
        onClick={toggleDd}
      >
        <li onClick={() => logout()}>{t("logout")}</li>
      </ul>
    </div>
  );
};
export default injectSheet(styles)(Header);

Header.propTypes = {
  classes: PropTypes.object,
};
