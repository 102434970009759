import React from "react";
import injectSheet from "react-jss";
import Input from "../../shared/Input/Input";
import Button from "../../shared/Button/Button";
import { Prompt } from "react-router";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useActivateUserFormik } from "./lib/useActivateUserFormik";
import API from "../../../api/API";

const ActivateUserForm = (props) => {
	const { classes } = props;
	const { t } = useTranslation();
	const formik = useActivateUserFormik({
		initialValues: {
			cardNumber: "",
		},
		async onSubmit(values, formikHelpers) {
			try {
				await API.activateUser(values.cardNumber);
				formikHelpers.resetForm();
				alert("Card activated");
			} catch (e) {
				formikHelpers.setFieldError(
					"cardNumber",
					e.response.data.message
				);
			}
		},
	});

	return (
		<form
			onSubmit={formik.handleSubmit}
			autoComplete="off"
			className={classes.root}
		>
			<div className={classes.fieldGroup}>
				<Prompt when={formik.dirty} message={t("onLeaveMessage")} />
				<Input
					name="cardNumber"
					label={t("cardNumber")}
					id="cardNumber"
					value={formik.values.cardNumber}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					error={
						!!formik.errors.cardNumber && formik.touched.cardNumber
					}
					helperText={
						formik.errors.cardNumber &&
						formik.touched.cardNumber &&
						formik.errors.cardNumber
					}
				/>
				<Button
					type="submit"
					label={t("activate")}
					loading={formik.isSubmitting}
					disabled={!formik.dirty}
				/>
			</div>
		</form>
	);
};

const styles = {
	root: {
		display: "flex",
		justifyContent: "space-between",
		"@media (max-width:768px)": {
			flexDirection: "column",
		},
	},
	fieldGroup: {
		width: "900px",
		marginRight: "20px",
		"@media (max-width:768px)": {
			width: "100%",
		},
		"& > *": {
			marginBottom: "40px",
		},
		"& :last-child": {
			marginBottom: 0,
		},
	},
	fileInput: {
		width: "300px",
		"@media (max-width:768px)": {
			width: "100%",
		},
	},
};

export default injectSheet(styles)(ActivateUserForm);

ActivateUserForm.propTypes = {
	classes: PropTypes.object,
};
