import { useFormik } from "formik";
import * as Yup from "yup";
import PropTypes from "prop-types";

export const useTermsAndPrivacyFormik = (props) => {
  const contactSchema = Yup.object().shape({
    terms_and_conditions: Yup.string(),
    privacy_policy: Yup.string(),
  });

  return useFormik({
    initialValues: {
      terms_and_conditions: "",
      privacy_policy: "",
      ...props.initialValues,
    },
    enableReinitialize: true,
    validationSchema: contactSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values, formikHelpers) => {
      await props.onSubmit(values, formikHelpers);
    },
  });
};

useTermsAndPrivacyFormik.propTypes = {
  props: PropTypes.shape({
    initialValues: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
  }),
};
