import { useFormik } from "formik";
import * as Yup from "yup";

const userSchema = Yup.object().shape({
  email: Yup.string().required("Email is required!"),
  password: Yup.string().required("Password is required!"),
  businessName: Yup.string().required("Business name is required!"),
  businessNumber: Yup.string().required("Business number is required!"),
  phoneNumber: Yup.string().required("Phone number is required!"),
});

export const useUserFormik = (props) =>
  useFormik({
    initialValues: {
      email: "",
      password: "",
      businessName: "",
      businessNumber: "",
      phoneNumber: "",
    },
    validationSchema: userSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values) => {
      await props.onSubmit(values);
    },
  });
