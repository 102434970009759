import React, { useEffect, useState } from "react";
import injectSheet from "react-jss";
import FormLayout from "../../layouts/PageLayout";
import { useTranslation } from "react-i18next";
import Table from "../../components/shared/Table/Table";
import Button from "../../components/shared/Button/Button";
import DropDown from "../../components/shared/DropDown/DropDown";
import API from "../../api/API";
import HandleLoading from "../../components/shared/HandleLoading/HandleLoading";
import HandleError from "../../components/shared/HandleError/HandleError";
import SendNotificationSkeleton from "./SendNotification.skeleton";
import { useUIContext } from "../../lib/context/UIContext/UIContext";
import PropTypes from "prop-types";
import { useConfirm } from "../../lib/context/ConfirmContext/ConfirmContext";

const SendNotification = (props) => {
  const { classes } = props;
  const { confirm } = useConfirm();
  const { isDesktop } = useUIContext();
  const { t, i18n } = useTranslation();
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [offers, setOffers] = useState([]);
  const [notificationsSent, setNotificationsSent] = useState([]);
  const [selectedOffer, setSelectedOffer] = useState({
    label: "",
    id: "",
  });
  const lang = i18n.language;

  //Fetch all offers
  useEffect(() => {
    API.offers
      .getAllOffers()
      .then((data) => {
        setOffers(
          data.map((offer) => ({
            label: offer.data.title,
            id: offer._id,
          }))
        );
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [lang]);

  //Get last 10 offers
  useEffect(() => {
    API.notification.getLastTen().then((data) => setNotificationsSent(data));
  }, []);

  const sendNotification = async () => {
    if (selectedOffer.id === "") return null;
    await confirm("Jeni i sigurte qe deshironi te dergoni njoftim per oferte?"); //TODO:ADD TRANSLATION
    const response = await API.notification.sendNotification(selectedOffer.id);

    setSuccessMessage(response?.data?.message);

    setSelectedOffer({
      label: "",
      id: "",
      price: "",
      content: "",
      offerImage: null,
    });
  };

  const columns = [
    {
      key: "offers_title",
      header: t("title"),
      sortable: true,
    },
    {
      key: "offers_content",
      header: t("content"),
      sortable: true,
    },
    {
      key: "offers_price",
      header: t("price"),
      sortable: true,
    },
  ];

  const rows = notificationsSent?.map((offer) => ({
    offers_title: <span>{offer.data.title}</span>,
    offers_content: <span>{offer.data.content}</span>,
    offers_price: <span>{offer.data.price}</span>,
  }));

  return (
    <HandleLoading
      loading={loading}
      loadingPlaceholder={<SendNotificationSkeleton />}
    >
      <HandleError>
        <FormLayout title={t("sendNotification").toUpperCase()}>
          <div className={classes.wrapper}>
            <DropDown
              mBottom={isDesktop === false}
              label={t("selectOffer")}
              options={offers}
              selectedOption={selectedOffer}
              onSelect={setSelectedOffer}
              setInputValue={(setFilter) => setFilter("")}
            />
            <Button label={t("send")} onClick={sendNotification} />
          </div>
          <p className={classes.successMessage}>{successMessage}</p>
          <Table title={t("sentNotifications")} columns={columns} rows={rows} />
        </FormLayout>
      </HandleError>
    </HandleLoading>
  );
};

const styles = {
  wrapper: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    marginBottom: "30px",
    "& button:nth-child(2)": {
      marginLeft: "15px",
    },
    "@media(max-width:768px)": {
      flexDirection: "column",
      alignItems: "start",
    },
  },
  successMessage: {
    color: "#1FB56F",
    margin: "0 0 10px 10px",
  },
};
export default injectSheet(styles)(SendNotification);

SendNotification.propTypes = {
  children: PropTypes.node,
};
