import React from "react";
import injectSheet from "react-jss";
import { useTranslation } from "react-i18next";
import cs from "classnames";
import SideBarItem from "./SideBarItem";
import SideBarHeader from "./SideBarHeader";
import { useUIContext } from "../../lib/context/UIContext/UIContext";

const styles = ({ palette, transitions }) => ({
  root: {
    background: palette.sidebarColor,
    position: "fixed",
    height: "100%",
    width: "350px",
    borderRight: "1px solid" + palette.borderColor,
    transition: transitions.common,
    "& > a": {
      textDecoration: "none",
    },
  },

  hide: {
    transform: "translateX(-350px)",
  },
  show: {
    transform: "translateX(0)",
  },
  "@media (max-width:768px)": {
    root: {
      // height: "100vh",
      zIndex: "10",
      transform: "translateX(-350px)",
    },
    show: {
      transform: "translateX(0)",
    },
  },
});

const SideBar = (props) => {
  const { classes } = props;
  const { sidebarOpen } = useUIContext();
  const { t } = useTranslation();

  const sidebarItems = [
    { icon: "faq", name: t("faqs"), path: "/faqs" },
    { icon: "offers", name: t("offers"), path: "/offers" },
    {
      icon: "notification",
      name: t("sendNotification"),
      path: "/offers/notify",
    },
    { icon: "category", name: t("categories"), path: "/categories" },
    // { icon: "products", name: t("products"), path: "/products" },
    {
      icon: "rating",
      name: t("transactionRatings"),
      path: "/transaction-ratings",
    },
    // { icon: "users", name: "Users", path: "/users" },
    { icon: "settings", name: t("settings"), path: "/settings" },
    { icon: "logs", name: t("logs"), path: "/logs" },
  ];

  return (
    <div
      className={cs(classes.root, sidebarOpen ? classes.show : classes.hide)}
    >
      <SideBarHeader />
      {sidebarItems.map((item, idx) => (
        <SideBarItem
          key={idx}
          icon={item.icon}
          name={item.name}
          path={item.path}
        />
      ))}
    </div>
  );
};

export default injectSheet(styles)(SideBar);
