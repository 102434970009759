import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

export const useContactFormik = (props) => {
  const { t } = useTranslation();
  const contactSchema = Yup.object().shape({
    phoneNumber: Yup.number()
      .required(t("formValidations.phoneNumber"))
      .typeError(t("invalidPhoneNumber")),
    email: Yup.string().required(t("formValidations.contactEmail")),
    address: Yup.string().required(t("formValidations.address")),
    clubSmart_card_benefits: Yup.string(),
    taxi_card_benefits: Yup.string(),
    agro_card_benefits: Yup.string(),
    vip_card_benefits: Yup.string(),
  });

  return useFormik({
    initialValues: {
      phoneNumber: "",
      email: "",
      address: "",
      clubSmart_card_benefits: "",
      taxi_card_benefits: "",
      agro_card_benefits: "",
      vip_card_benefits: "",
      ...props.initialValues,
    },
    enableReinitialize: true,
    validationSchema: contactSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values, formikHelpers) => {
      await props.onSubmit(values, formikHelpers);
    },
  });
};

useContactFormik.propTypes = {
  props: PropTypes.shape({
    initialValues: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
  }),
};
