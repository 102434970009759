import React from "react";
import injectSheet from "react-jss";
import Input from "../../shared/Input/Input";
import Button from "../../shared/Button/Button";
import Image from "../../shared/Image/Image";
import { useUIContext } from "../../../lib/context/UIContext/UIContext";
import { Prompt } from "react-router";
import { useTranslation } from "react-i18next";
import FileInput from "../../shared/FileInput/FileInput";
import TranslationReminder from "../../shared/TranslationReminder/TranslationReminder";
import FieldVersionInOtherLang from "../../shared/FieldVersionInOtherLang/FieldVersionInOtherLang";
import PropTypes from "prop-types";

const CategoryForm = (props) => {
  const { classes, formik, editMode } = props;
  const { isDesktop } = useUIContext();
  const { t } = useTranslation();

  const translations = formik.values.translations;

  return (
    <form
      onSubmit={formik.handleSubmit}
      autoComplete="off"
      className={classes.root}
    >
      <div className={classes.fieldGroup}>
        <Prompt when={formik.dirty} message={t("onLeaveMessage")} />
        {editMode && <TranslationReminder translations={translations} />}
        {editMode && (
          <FieldVersionInOtherLang translations={translations} field="name" />
        )}
        <Input
          name="name"
          label={t("nameForDashboard")}
          id="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={!!formik.errors.name && formik.touched.name}
          helperText={
            formik.errors.name && formik.touched.name && formik.errors.name
          }
        />
        {editMode && (
          <FieldVersionInOtherLang translations={translations} field="name" />
        )}
        <Input
          name="description"
          label={t("description")}
          id="description"
          multiLine
          value={formik.values.description}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={!!formik.errors.description && formik.touched.description}
          helperText={
            formik.errors.description &&
            formik.touched.description &&
            formik.errors.description
          }
        />
        <Input
          name="id"
          label="Id"
          id="id"
          value={formik.values.id}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={!!formik.errors.id && formik.touched.id}
          helperText={formik.errors.id && formik.touched.id && formik.errors.id}
        />
        <FileInput
          onChange={(file) => formik.setFieldValue("img", file, true)}
          error={formik.errors.img && formik.errors.img}
        />
        {!isDesktop && (
          <Image
            src={formik.values.img}
            alt="Category"
            style={{
              objectFit: "cover",
              width: "100%",
              height: "150px",
              marginBottom: "30px",
            }}
          />
        )}
        <Button
          className={classes.button}
          type="submit"
          label={editMode ? t("edit") : t("addCategory")}
          loading={formik.isSubmitting}
          disabled={!formik.dirty}
        />
      </div>
      {isDesktop && (
        <Image
          src={formik.values.img}
          alt="Category"
          style={{
            objectFit: "cover",
            width: "250px",
            height: "250px",
          }}
        />
      )}
    </form>
  );
};

const styles = {
  root: {
    display: "flex",
    justifyContent: "space-between",
    "@media (max-width:768px)": {
      flexDirection: "column",
    },
  },
  fieldGroup: {
    display: "flex",
    flexDirection: "column",
    width: "900px",
    marginRight: "20px",
    "@media (max-width:768px)": {
      width: "100%",
    },
    "& > *": {
      marginBottom: "40px",
    },
    "& :last-child": {
      marginBottom: 0,
    },
  },
  fileInput: {
    width: "300px",
    "@media (max-width:768px)": {
      width: "100%",
    },
  },
  button: {
    width: "130px",
  },
};

export default injectSheet(styles)(CategoryForm);

CategoryForm.propTypes = {
  classes: PropTypes.object,
  formik: PropTypes.object.isRequired,
};
