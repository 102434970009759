import React from "react";
import TransactionRatingsTable from "../../components/tables/TransactionRatingsTable/TransactionRatingsTable";

export const TransactionRatings = () => {
  return (
    <div>
      <TransactionRatingsTable />
    </div>
  );
};
