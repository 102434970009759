import React from "react";
import injectSheet from "react-jss";
import { Icon } from "../Icon/Icon";
const EditorToolbar = (props) => {
  const { classes, toggleInlineStyle, toggleBlockType } = props;
  return (
    <div className={classes.editorToolbar}>
      <button
        type="button"
        onClick={() => toggleInlineStyle("BOLD")}
        className={classes.button}
      >
        <strong>B</strong>
      </button>
      <button
        type="button"
        onClick={() => toggleInlineStyle("ITALIC")}
        className={classes.button}
      >
        <i>I</i>
      </button>
      <button
        type="button"
        onClick={() => toggleBlockType("unordered-list-item")}
        className={classes.button}
      >
        <Icon className={classes.icon} icon="unordered-list" />
      </button>
      <button
        type="button"
        onClick={() => toggleBlockType("ordered-list-item")}
        className={classes.button}
      >
        <Icon className={classes.icon} icon="ordered-list" />
      </button>
      <button
        type="button"
        onClick={() => toggleBlockType("header-one")}
        className={classes.button}
      >
        H1
      </button>
      <button
        type="button"
        onClick={() => toggleBlockType("header-two")}
        className={classes.button}
      >
        H2
      </button>
      <button
        type="button"
        onClick={() => toggleBlockType("header-three")}
        className={classes.button}
      >
        H3
      </button>
      <button
        onClick={() => toggleBlockType("unstyled")}
        type="button"
        className={classes.button}
      >
        Normal
      </button>
    </div>
  );
};

const styles = ({ palette, boxShadow }) => ({
  editorToolbar: {
    position: "absolute",
    top: 0,
    left: 0,
    padding: "15px 10px",
    width: "100%",
    zIndex: 2,
    boxShadow: boxShadow.common,
    backgroundColor: palette.sidebarColor,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  button: {
    background: palette.common.white,
    height: "35px",
    padding: "0 10px",
    marginRight: "5px",
    cursor: "pointer",
  },
  icon: {
    width: "1.1rem",
    height: "1.1rem",
    // margin: "-3px",
  },
});
export default injectSheet(styles)(EditorToolbar);
