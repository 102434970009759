import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
export const useActivateUserFormik = (props) => {
	const { t } = useTranslation();

	const activateUserSchema = Yup.object().shape({
		cardNumber: Yup.string().required(t("formValidations.cardNumber")),
	});

	return useFormik({
		initialValues: {
			cardNumber: "",
			...props.initialValues,
		},
		enableReinitialize: true,
		validationSchema: activateUserSchema,
		validateOnChange: true,
		validateOnBlur: true,
		onSubmit: async (values, formikHelpers) => {
			await props.onSubmit(values, formikHelpers);
		},
	});
};

useActivateUserFormik.propTypes = {
  props: PropTypes.shape({
    initialValues: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
  }),
};
