import React from "react";
import injectSheet from "react-jss";
import { Icon } from "../Icon/Icon";
import PropTypes from "prop-types";

const LoadingScreen = (props) => {
  const { classes } = props;
  return (
    <div className={classes.root}>
      <Icon icon="shell" className={classes.icon} />
    </div>
  );
};
const styles = ({ palette }) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: palette.leadColor,
    margin: 0,
    padding: 0,
    width: "100vw",
    height: "100vh",
    minWidth: "100%",
    minHeight: "100%",
  },
  icon: {
    width: "100px",
    height: "100px",
  },
});
export default injectSheet(styles)(LoadingScreen);

LoadingScreen.propTypes = {
  classes: PropTypes.object,
};
