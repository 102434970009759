import React, { useEffect, useState } from "react";
import injectSheet from "react-jss";
import Table from "../../shared/Table/Table";
import Button from "../../shared/Button/Button";
import API from "../../../api/API";
import Image from "../../shared/Image/Image";
import { useRouter } from "../../../lib/hooks/useRouter";
import { useTranslation } from "react-i18next";
import { useConfirm } from "../../../lib/context/ConfirmContext/ConfirmContext";
import PropTypes from "prop-types";

const OffersTable = (props) => {
  const { classes } = props;
  const { confirm } = useConfirm();
  const router = useRouter();
  const [deleting, setDeleting] = useState(null);
  const [offers, setOffers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { t, i18n } = useTranslation();

  const lang = i18n.language;

  useEffect(() => {
    setLoading(true);
    API.offers
      .getAllOffers()
      .then((data) => {
        setOffers(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setError(err.toString());
      });
  }, [lang]);

  const handleDelete = async (id) => {
    try {
      await confirm();
      setDeleting(id);
      await API.offers.deleteOffer(id);
      setOffers((prev) => prev.filter((offer) => offer._id !== id));
      setDeleting(null);
    } catch (e) {
      setDeleting(null);
    }
  };

  const columns = [
    {
      key: "offers_image",
      header: t("image"),
    },
    {
      key: "offers_title",
      header: t("title"),
      sortable: true,
    },
    // {
    //   key: "offers_content",
    //   header: t("content"),
    //   sortable: true,
    // },
    {
      key: "offers_price",
      header: t("price"),
      sortable: true,
    },
    {
      key: "offers_actions",
      header: t("actions"),
    },
  ];

  const rows = offers.map((offer) => ({
    offers_image: (
      <div className={classes.wrapper}>
        <div className={classes.imgThumb}>
          <Image src={offer.offerImage} alt="asd" className={classes.img} />
        </div>
      </div>
    ),
    offers_title: (
      <span data-sort={offer.data.title}>
        {offer.data.title || (
          <span className={classes.warningText}>{t("noTitle")}</span>
        )}
      </span>
    ),
    // offers_content: (
    //   <span data-sort={offer.data.content}>{offer.data.content}</span>
    // ),
    offers_price: <span data-sort={offer.data.price}>{offer.data.price}</span>,
    offers_actions: (
      <div className={classes.buttonsWrapper}>
        <Button
          type="danger"
          label={t("delete")}
          onClick={() => handleDelete(offer._id)}
          loading={deleting === offer._id}
        />
        <Button
          label={t("edit")}
          onClick={() => router.history.push(`offers/edit/${offer._id}`)}
        />
      </div>
    ),
  }));

  return (
    <Table
      title={t("offers")}
      columns={columns}
      rows={rows}
      loading={loading}
      error={error}
      button={
        <Button
          label={t("addOffer")}
          onClick={() => router.history.push("offers/add")}
        />
      }
    />
  );
};

const styles = ({ palette, boxShadow }) => ({
  wrapper: {
    position: "relative",
  },
  buttonsWrapper: { display: "flex", justifyContent: "center" },

  imgThumb: {
    backgroundColor: "lightgray",
    width: "40px",
    height: "40px",
    borderRadius: "100px",
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  img: {
    objectFit: "cover",
    height: "40px",
    "&:hover ": {
      position: "absolute",
      zIndex: 200,
      left: 0,
      bottom: 0,
      height: "150px",
      borderRadius: "5px",
      boxShadow: boxShadow.common,
    },
  },
  warningText: {
    color: palette.button.warning,
    fontSize: "1rem",
  },
});

export default injectSheet(styles)(OffersTable);

OffersTable.propTypes = {
  classes: PropTypes.object,
};
