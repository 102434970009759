import React from "react";
import injectSheet from "react-jss";
import Input from "../../shared/Input/Input";
import { useTranslation } from "react-i18next";
import Button from "../../shared/Button/Button";
import { Prompt } from "react-router";
import PropTypes from "prop-types";
import FormLayout from "../../../layouts/PageLayout";
const ContactForm = (props) => {
  const { classes, formik, successMessage, errorMessage } = props;
  const { t } = useTranslation();
  return (
    <form onSubmit={formik.handleSubmit}>
      <FormLayout title={t("contact")}>
        <div className={classes.fieldGroup}>
          <Prompt when={formik.dirty} message={t("onLeaveMessage")} />
          <Input
            label={t("phoneNumber")}
            id="phoneNumber"
            value={formik.values.phoneNumber}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={!!formik.errors.phoneNumber && formik.touched.phoneNumber}
            helperText={
              formik.errors.phoneNumber &&
              formik.touched.phoneNumber &&
              formik.errors.phoneNumber
            }
          />
          <Input
            label={t("email")}
            id="email"
            type="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={!!formik.errors.email && formik.touched.email}
            helperText={
              formik.errors.email && formik.touched.email && formik.errors.email
            }
          />
          <Input
            label={t("address")}
            id="address"
            value={formik.values.address}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={!!formik.errors.address && formik.touched.address}
            helperText={
              formik.errors.address &&
              formik.touched.address &&
              formik.errors.address
            }
          />
          <Button
            label={t("update")}
            type="submit"
            disabled={!formik.dirty}
            loading={formik.isSubmitting}
          />
        </div>
        <p className={classes.successMessage}>{successMessage}</p>
        <p className={classes.errorMessage}>{errorMessage}</p>
      </FormLayout>
    </form>
  );
};

const styles = ({ palette, typography }) => ({
  fieldGroup: {
    maxWidth: "900px",
    marginRight: "20px",
    "& > *": {
      marginBottom: "40px",
    },
    "& :last-child": {
      marginBottom: 0,
    },
  },
  successMessage: {
    marginTop: "30px",
    fontWeight: typography.weight.medium,
    color: palette.button.success,
  },
  errorMessage: {
    color: palette.button.danger,
  },
  cardBenefits: {
    fontWeight: typography.weight.medium,
    fontSize: typography.headingFontSize,
  },
});

export default injectSheet(styles)(ContactForm);

ContactForm.propTypes = {
  classes: PropTypes.object,
  formik: PropTypes.object.isRequired,
  successMessage: PropTypes.string,
};
