import React from "react";
import injectSheet from "react-jss";
import Input from "../../shared/Input/Input";
import { useTranslation } from "react-i18next";
import Button from "../../shared/Button/Button";
import PropTypes from "prop-types";
import FormLayout from "../../../layouts/PageLayout";
const ContactForm = (props) => {
  const { classes, formik, successMessage } = props;
  const { t } = useTranslation();
  return (
    <form onSubmit={formik.handleSubmit}>
      <FormLayout title={t("termsAndConditions") + ", " + t("privacyPolicy")}>
        <div className={classes.fieldGroup}>
          <Input
            multiLine
            label={t("termsAndConditions")}
            id="terms_and_conditions"
            value={formik.values.terms_and_conditions}
            onChange={formik.handleChange}
          />
          <Input
            multiLine
            label={t("privacyPolicy")}
            id="privacy_policy"
            value={formik.values.privacy_policy}
            onChange={formik.handleChange}
          />
          <Button
            label={t("update")}
            type="submit"
            disabled={!formik.dirty}
            loading={formik.isSubmitting}
          />
        </div>
        <p className={classes.successMessage}>{successMessage}</p>
      </FormLayout>
    </form>
  );
};

const styles = ({ palette, typography }) => ({
  fieldGroup: {
    maxWidth: "900px",
    marginRight: "20px",
    "& > *": {
      marginBottom: "40px",
    },
    "& :last-child": {
      marginBottom: 0,
    },
  },
  successMessage: {
    marginTop: "30px",
    fontWeight: typography.weight.medium,
    color: palette.button.success,
  },
  errorMessage: {
    color: palette.button.danger,
  },
  cardBenefits: {
    fontWeight: typography.weight.medium,
    fontSize: typography.headingFontSize,
  },
});

export default injectSheet(styles)(ContactForm);

ContactForm.propTypes = {
  classes: PropTypes.object,
  formik: PropTypes.object.isRequired,
  successMessage: PropTypes.string,
};
