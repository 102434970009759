import React, { useEffect, useState } from "react";
import injectSheet from "react-jss";
import Table from "../../shared/Table/Table";
import API from "../../../api/API";
import Button from "../../shared/Button/Button";
import { Icon } from "../../shared/Icon/Icon";
import { useTranslation } from "react-i18next";

const SmsTable = (props) => {
  const { classes } = props;
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    API.logs
      .getSmsLogs()
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const SuccessCircle = <div className={classes.successCircle}> </div>;

  const ErrorCircle = <div className={classes.errorCircle}> </div>;

  const columns = [
    {
      key: "isSuccess",
      header: t("isSuccess"),
    },
    {
      key: "phoneNumber",
      header: t("phoneNumber"),
    },
    {
      key: "body",
      header: t("body"),
    },
    {
      key: "date",
      header: t("date"),
    },
    {
      key: "type",
      header: t("type"),
    },
    {
      key: "response",
      header: t("response"),
    },
  ];

  const rows = data?.logs?.map((sms) => ({
    isSuccess: (
      <span>{sms.level === "info" ? SuccessCircle : ErrorCircle}</span>
    ),
    phoneNumber: <span>{sms?.message?.phoneNumber}</span>,
    body: <span>{sms?.message?.body}</span>,
    date: <span>{sms?.message?.date}</span>,
    type: <span>{sms?.message?.type}</span>,
    response: <span>{JSON.stringify(sms?.message?.response)}</span>,
  }));

  return (
    <Table
      title={
        <div className={classes.wrapper}>
          SMS: Is enabled{data?.isEnabled ? SuccessCircle : ErrorCircle}
        </div>
      }
      button={<Button label={<Icon icon="refetch" />} onClick={fetchData} />}
      columns={columns}
      loading={loading}
      rows={rows}
    />
  );
};

const styles = {
  successCircle: {
    marginLeft: "5px",
    width: "20px",
    height: "20px",
    borderRadius: "100px",
    background: "green",
    display: "inline-block",
  },
  errorCircle: {
    marginLeft: "5px",
    width: "20px",
    height: "20px",
    borderRadius: "100px",
    background: "red",
    display: "inline-block",
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
  },
};
export default injectSheet(styles)(SmsTable);
