import React from "react";
import injectSheet from "react-jss";
import Input from "../../shared/Input/Input";
import Button from "../../shared/Button/Button";
import { Prompt } from "react-router";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import TextEditor from "../../shared/TextEditor/TextEditor";
import TranslationReminder from "../../shared/TranslationReminder/TranslationReminder";
import FieldVersionInOtherLang from "../../shared/FieldVersionInOtherLang/FieldVersionInOtherLang";

const styles = ({ palette }) => ({
  fieldGroup: {
    maxWidth: "900px",
    marginRight: "20px",
    "& > *": {
      marginBottom: "40px",
    },
    "& :last-child": {
      marginBottom: 0,
    },
  },
  checkbox: {
    height: "15px",
    width: "15px",
    marginBottom: "30px",
  },
  checkboxLabel: {
    fontSize: "1.2rem",
    color: palette.leadTextColor,
    marginLeft: "10px",
  },
});

const FAQForm = (props) => {
  const { formik, classes, editMode } = props;
  const { t } = useTranslation();

  return (
    <form onSubmit={formik.handleSubmit} autoComplete="off">
      <div className={classes.fieldGroup}>
        <Prompt when={formik.dirty} message={t("onLeaveMessage")} />
        {editMode && (
          <TranslationReminder translations={formik.values.translations} />
        )}
        {editMode && (
          <FieldVersionInOtherLang
            translations={formik.values.translations}
            field="title"
          />
        )}
        <Input
          name="title"
          label={t("title")}
          id="title"
          value={formik.values.title}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={!!formik.errors.title && formik.touched.title}
          helperText={
            formik.errors.title && formik.touched.title && formik.errors.title
          }
        />
        {editMode && (
          <FieldVersionInOtherLang
            translations={formik.values.translations}
            field="description"
          />
        )}
        <TextEditor
          label={t("description")}
          value={formik.values.description}
          onChange={(editor) => formik.setFieldValue("description", editor)}
        />
        <Button
          type="submit"
          label={editMode ? t("edit") : t("addFAQ")}
          loading={formik.isSubmitting}
        />
      </div>
    </form>
  );
};

export default injectSheet(styles)(FAQForm);

FAQForm.propTypes = {
  classes: PropTypes.object,
  formik: PropTypes.object.isRequired,
};
