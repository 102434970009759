import React from "react";
import injectSheet from "react-jss";
import Skeleton from "react-loading-skeleton";

const FormSkeleton = (props) => {
  const { classes } = props;
  return (
    <div className={classes.root}>
      <p className={classes.title}>
        <Skeleton />
      </p>
      <div className={classes.form}>
        <div>
          <div className={classes.input}>
            <Skeleton height="55px" />
          </div>
          <div className={classes.input}>
            <Skeleton height="75px" />
          </div>
          <div className={classes.input}>
            <Skeleton height="55px" />
          </div>
          <div className={classes.smInput}>
            <Skeleton height="55px" />
          </div>
        </div>
        <div className={classes.img}>
          <Skeleton height="250px" />
        </div>
      </div>
      <Skeleton width="100px" height="40px" />
    </div>
  );
};

const styles = ({ typography, size, boxShadow, palette }) => ({
  root: {
    borderRadius: "8px",
    background: palette.common.white,
    boxShadow: boxShadow.common,
    padding: size.spacing,
    marginBottom: size.spacing,
    "@media(max-width:768px)": {
      padding: size.spacing / 1.5,
    },
  },
  title: {
    fontSize: typography.headingFontSize,
    width: "100px",
    margin: 0,
    marginBottom: size.spacing,
  },
  form: {
    display: "flex",
    justifyContent: "space-between",
    "@media (max-width:768px)": {
      flexDirection: "column",
    },
  },
  input: {
    width: "900px",
    marginBottom: size.spacing - 10,
    "@media(max-width:768px)": {
      width: "100%",
    },
  },
  smInput: {
    width: "300px",
    marginBottom: size.spacing - 10,
    "@media(max-width:768px)": {
      width: "80%",
    },
  },
  img: {
    width: "250px",
    marginBottom: "30px",
    "@media(max-width:768px)": {
      width: "100%",
    },
  },
});
export default injectSheet(styles)(FormSkeleton);
