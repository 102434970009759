import React from "react";
import Input from "../../shared/Input/Input";
import Button from "../../shared/Button/Button";
import injectSheet from "react-jss";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

const styles = {
  form: {
    "& > *": {
      marginBottom: "30px",
    },
    "& :last-child": {
      marginBottom: 0,
    },
  },
};

const LoginForm = (props) => {
  const { t } = useTranslation();
  const { formik, error, classes } = props;
  return (
    <form onSubmit={formik.handleSubmit} className={classes.form}>
      <Input
        id="email"
        name="email"
        label={t("email")}
        type="email"
        value={formik.values.email}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={!!formik.errors.email && formik.touched.email}
        helperText={
          formik.errors.email && formik.touched.email && formik.errors.email
        }
      />
      <Input
        id="password"
        name="password"
        label={t("password")}
        type="password"
        value={formik.values.password}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={!!formik.errors.password && formik.touched.password}
        helperText={
          formik.errors.password &&
          formik.touched.password &&
          formik.errors.password
        }
      />
      <Button label={t("login")} type="submit" loading={formik.isSubmitting} />
      {error && <label style={{ color: "red" }}>{error}</label>}
    </form>
  );
};
export default injectSheet(styles)(LoginForm);
LoginForm.propTypes = {
  classes: PropTypes.object,
  formik: PropTypes.object.isRequired,
  error: PropTypes.string,
};
