import React, { useEffect, useState } from "react";
import injectSheet from "react-jss";
import Table from "../../shared/Table/Table";
import API from "../../../api/API";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Icon } from "../../shared/Icon/Icon";
import RoundButton from "../../shared/RoundButton/RoundButton";

const TransactionRatingsTable = (props) => {
  const { classes } = props;
  const { t } = useTranslation();
  const [transactionRatings, setTransactionRatings] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const PER_PAGE_LIMIT = 10;

  const loadTransactions = async (page) => {
    try {
      setLoading(true);
      setPage(page);

      const data = await API.transactionRatings.getTransactionRatings({
        limit: PER_PAGE_LIMIT,
        page,
      });
      console.log("data", data);
      setTransactionRatings((prevTransactionRatings) => [
        ...(page === 1 ? [] : prevTransactionRatings),
        ...data.data,
      ]);
      setHasMore(data.has_more);
    } catch (err) {
      setError(err.toString());
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadTransactions(1);
  }, []);

  const loadMore = () => {
    if (loading || !hasMore) return;

    loadTransactions(page + 1);
  };

  const columns = [
    {
      key: "rating",
      header: t("rating"),
    },
    {
      key: "ratingMessage",
      header: t("ratingMessage"),
    },
    {
      key: "transactionData",
      header: t("transactionData"),
    },
    {
      key: "raterName",
      header: t("nameForDashboard"),
    },
    {
      key: "raterPhoneNumber",
      header: t("phoneNumber"),
    },
    {
      key: "raterCardNumber",
      header: t("cardNumber"),
    },
    {
      key: "raterEmail",
      header: t("email"),
    },
  ];

  const rows = transactionRatings.map((transactionRating) => ({
    rating: (
      <span className={classes.rating}>
        {Array.from({ length: transactionRating.rating }, () => (
          <Icon icon="rating" className={classes.icon} />
        ))}
      </span>
    ),
    ratingMessage: <span>{transactionRating.ratingMessage}</span>,
    transactionData: <span>{transactionRating.transactionData}</span>,
    raterName: <span>{transactionRating.user.name}</span>,
    raterPhoneNumber: <span>{transactionRating.user.phoneNumber}</span>,
    raterCardNumber: <span>{transactionRating.user.cardNumber}</span>,
    raterEmail: <span>{transactionRating.user.email}</span>,
  }));

  return (
    <>
      <Table
        title={t("transactionRatings")}
        columns={columns}
        rows={rows}
        loading={loading && page === 1}
        error={error}
      />

      {hasMore && (
        <RoundButton
          className={classes.loadMoreButton}
          label={t("loadMore")}
          onClick={loadMore}
          loading={loading && page > 1}
        />
      )}
    </>
  );
};

const styles = ({ palette }) => ({
  rating: {
    display: "flex",
    alignItems: "center",
  },
  icon: {
    width: "20px",
    height: "20px",
    fill: palette.button.warning,
  },
  loadMoreButton: {
    margin: "15px auto",
  },
});

export default injectSheet(styles)(TransactionRatingsTable);

TransactionRatingsTable.propTypes = {
  classes: PropTypes.object,
};
