import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { convertFromHTML, ContentState, EditorState } from "draft-js";
import api from "../../api/API";
import { useFAQFormik } from "../../components/forms/FAQForm/lib/useFAQFormik";
import FAQForm from "../../components/forms/FAQForm/FAQForm";
import HandleLoading from "../../components/shared/HandleLoading/HandleLoading";
import HandleError from "../../components/shared/HandleError/HandleError";
import FormLayout from "../../layouts/PageLayout";
import API from "../../api/API";
import { useLanguageContext } from "../../lib/context/LanguageContext/LanguageContext";
import { useRouter } from "../../lib/hooks/useRouter";
import FormSkeleton from "../../components/forms/Form.skeleton";
import { convertToHTML } from "draft-convert";

export const EditFAQ = (props) => {
  const { id } = props.match.params;
  const { getLngPrefix } = useLanguageContext();
  const router = useRouter();
  const [initialValues, setInitialValues] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const { t, i18n } = useTranslation();

  const formik = useFAQFormik({
    initialValues,
    onSubmit: async (values, formikHelpers) => {
      const descriptionConvertedToHTML = convertToHTML(
        values.description.getCurrentContent()
      );

      await API.faqs.updateFAQ(id, {
        ...values,
        description: descriptionConvertedToHTML,
      });
      formikHelpers.resetForm({ values: { ...values } });
      router.history.push(`${getLngPrefix()}/faqs`);
    },
  });

  useEffect(() => {
    api.faqs
      .getFAQ(id)
      .then((data) => {
        const blocksFromHTML = convertFromHTML(data.faq.description);
        const contentState = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap
        );
        const descriptionEditorState = EditorState.createWithContent(
          contentState
        );

        setInitialValues({
          title: data.faq.title,
          description: descriptionEditorState,
          translations: data.translations,
        });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setError(err.toString());
      });
  }, [id, i18n.language]);

  return (
    <HandleLoading loading={loading} loadingPlaceholder={<FormSkeleton />}>
      <HandleError error={error}>
        <FormLayout title={t("editFAQ")}>
          <FAQForm formik={formik} editMode />
        </FormLayout>
      </HandleError>
    </HandleLoading>
  );
};
