import React from "react";
import cs from "classnames";
import injectSheet from "react-jss";
import { useAuthContext } from "../../lib/context/AuthContext/AuthContextProvider";
import PropTypes from "prop-types";

const UserInfo = (props) => {
  const { user } = useAuthContext();
  const { classes, onClick, ddOpen, userRef } = props;
  return (
    <div
      className={cs(classes.root, ddOpen && classes.rotateTriangle)}
      onClick={onClick}
      ref={userRef}
    >
      {/*<div className={classes.avatar}> </div>*/}
      {user.email}
    </div>
  );
};

const styles = ({ palette, typography, transitions }) => ({
  root: {
    marginLeft: "50px",
    display: "flex",
    position: "relative",
    alignItems: "center",
    color: palette.secondaryTextColor,
    fontSize: typography.headingFontSize,
    height: "60px",
    cursor: "pointer",
    userSelect: "none",
    "&:after": {
      transition: transitions.common,
      content: '""',
      position: "absolute",
      right: -16,
      top: 27,
      width: 0,
      height: 0,
      borderStyle: "solid",
      borderWidth: "7px 6px 0 6px",
      borderColor: "#434d71 transparent transparent transparent",
    },
  },
  rotateTriangle: {
    "&:after": {
      transform: "scaleY(-1)",
    },
  },
  avatar: {
    marginRight: "18px",
    width: 60,
    height: 60,
    borderRadius: 60,
    background: "gray",
  },
});

export default injectSheet(styles)(UserInfo);

UserInfo.propTypes = {
  classes: PropTypes.object,
  onClick: PropTypes.func,
  ddOpen: PropTypes.bool,
  userRef: PropTypes.object,
};
