import React, { useEffect, useState } from "react";
import Table from "../../shared/Table/Table";
import API from "../../../api/API";
import Button from "../../shared/Button/Button";
import { Icon } from "../../shared/Icon/Icon";
import { useTranslation } from "react-i18next";

export const VerificationCodeTable = () => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    API.logs.getVerificationCodeLogs().then((data) => {
      setData(data);
      setLoading(false);
    });
  };
  const columns = [
    {
      key: "phone_number",
      header: t("phoneNumber"),
    },
    {
      key: "verification_code",
      header: t("verificationCode"),
    },
    {
      key: "card_number",
      header: t("cardNumber"),
    },
    {
      key: "date",
      header: t("date"),
    },
  ];

  const rows = data.map((verCode) => ({
    phone_number: <span>{verCode.message.userData.phoneNumber}</span>,
    card_number: <span>{verCode.message.userData.cardNumber}</span>,
    verification_code: <span>{verCode.message.userData.verificationCode}</span>,
    date: <span>{verCode.message.date}</span>,
  }));

  return (
    <Table
      title="Verification Codes"
      button={<Button label={<Icon icon="refetch" />} onClick={fetchData} />}
      loading={loading}
      columns={columns}
      rows={rows}
    />
  );
};
