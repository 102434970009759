import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

export const useLoginFormik = (props) => {
  const { t } = useTranslation();

  const loginSchema = Yup.object().shape({
    email: Yup.string().required(t("formValidations.email")),
    password: Yup.string().required(t("formValidations.password")),
  });

  return useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: async (values, formikHelpers) =>
      await props.onSubmit(values, formikHelpers),
  });
};

useLoginFormik.propTypes = {
  props: PropTypes.shape({
    onSubmit: PropTypes.func.isRequired,
  }),
};
