import React from "react";
import Input from "../../shared/Input/Input";
import Button from "../../shared/Button/Button";
import injectSheet from "react-jss";
import { Prompt } from "react-router";

const styles = {
  fieldGroup: {
    maxWidth: "900px",
    position: "relative",
    "& > *": {
      marginBottom: "30px",
    },
  },
};

const UserForm = (props) => {
  const { classes, formik, submitting } = props;
  return (
    <form onSubmit={formik.handleSubmit}>
      <Prompt
        when={submitting ? false : formik.dirty}
        message="You have unsaved changes, are u sure u want to leave?"
      />
      <div className={classes.fieldGroup}>
        <Input
          label="Email"
          name="email"
          id="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={!!formik.errors.email && formik.touched.email}
          helperText={
            formik.errors.email && formik.touched.email && formik.errors.email
          }
        />
        <Input
          label="Password"
          name="password"
          id="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={!!formik.errors.password && formik.touched.password}
          helperText={
            formik.errors.password &&
            formik.touched.password &&
            formik.errors.password
          }
        />
        <Input
          label="Business Name"
          name="businessName"
          id="businessName"
          value={formik.values.businessName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={!!formik.errors.businessName && formik.touched.businessName}
          helperText={
            formik.errors.businessName &&
            formik.touched.businessName &&
            formik.errors.businessName
          }
        />
        <Input
          label="Business Number"
          name="businessNumber"
          id="businessNumber"
          value={formik.values.businessNumber}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            !!formik.errors.businessNumber && formik.touched.businessNumber
          }
          helperText={
            formik.errors.businessNumber &&
            formik.touched.businessNumber &&
            formik.errors.businessNumber
          }
        />
        <Input
          label="Phone Number"
          name="phoneNumber"
          id="phoneNumber"
          value={formik.values.phoneNumber}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={!!formik.errors.phoneNumber && formik.touched.phoneNumber}
          helperText={
            formik.errors.phoneNumber &&
            formik.touched.phoneNumber &&
            formik.errors.phoneNumber
          }
        />
      </div>
      <Button type="submit" label="Add User" disabled={!formik.dirty} />
    </form>
  );
};
export default injectSheet(styles)(UserForm);
