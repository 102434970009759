import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import injectSheet from "react-jss";
import Table from "../../shared/Table/Table";
import API from "../../../api/API";
import Button from "../../shared/Button/Button";
import { useRouter } from "../../../lib/hooks/useRouter";
import { useConfirm } from "../../../lib/context/ConfirmContext/ConfirmContext";
import PropTypes from "prop-types";

const FAQsTable = (props) => {
  const router = useRouter();
  const { confirm } = useConfirm();
  const [faqs, setFaqs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(null);
  const [error, setError] = useState("");
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const { classes } = props;

  useEffect(() => {
    setLoading(true);
    API.faqs
      .getAllFAQs()
      .then((data) => {
        setLoading(false);
        setFaqs(data);
      })
      .catch((err) => {
        setLoading(false);
        setError(err.toString());
      });
  }, [lang]);

  const handleDelete = async (id) => {
    try {
      await confirm();
      setDeleting(id);
      await API.faqs.deleteFAQ(id);
      setFaqs((prevState) => prevState.filter((item) => item._id !== id));
      setDeleting(null);
    } catch (e) {
      setDeleting(null);
    }
  };

  const columns = [
    {
      key: "faqs_title",
      header: t("title"),
      sortable: true,
    },
    // {
    //   key: "faqs_description",
    //   header: t("description"),
    //   sortable: true,
    // },
    {
      key: "faqs_actions",
      header: t("actions"),
    },
  ];

  const rows = faqs.map((faq) => ({
    faqs_title: (
      <span data-sort={faq.data.title}>
        {faq.data.title || (
          <span className={classes.warningText}>{t("noTitle")}</span>
        )}
      </span>
    ),
    // faqs_description: (
    //   <span data-sort={faq.data.description}>{faq.data.description}</span>
    // ),
    faqs_actions: (
      <div className={classes.buttonsWrapper}>
        <Button
          type="danger"
          label={t("delete")}
          onClick={() => handleDelete(faq._id)}
          loading={deleting === faq._id}
        />
        <Button
          label={t("edit")}
          onClick={() => router.history.push(`faqs/edit/${faq._id}`)}
        />
      </div>
    ),
  }));

  return (
    <>
      <Table
        title="FAQs"
        columns={columns}
        rows={rows}
        loading={loading}
        error={error}
        button={
          <Button
            label={t("addFAQ")}
            onClick={() => router.history.push("faqs/add")}
          />
        }
      />
    </>
  );
};
const styles = ({ palette }) => ({
  warningText: {
    color: palette.button.warning,
    fontSize: "1rem",
  },
  buttonsWrapper: {
    display: "flex",
    justifyContent: "center",
    whiteSpace: "nowrap",
  },
});
export default injectSheet(styles)(FAQsTable);

FAQsTable.propTypes = {
  classes: PropTypes.object,
};
