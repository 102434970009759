import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

const FALLBACK_IMG = require("../../../assets/images/fallback-img.png");
const UPLOAD_DIR = "https://api.shell.ksc-clients.com/";
const Image = (props) => {
  const { src, alt, ...rest } = props;
  const [thumb, setThumb] = useState();

  const onError = (e) => {
    e.target.src = FALLBACK_IMG;
  };

  useEffect(() => {
    if (typeof src === "object") {
      let reader = new FileReader();
      reader.onloadend = () => {
        setThumb(reader.result);
      };
      reader.readAsDataURL(src);
    }
    return setThumb(undefined); //Remove last image from state when file is not selected
  }, [src]);

  return (
    <img
      src={thumb || (src && UPLOAD_DIR + src) || FALLBACK_IMG}
      alt={alt || "Image"}
      onError={onError}
      {...rest}
    />
  );
};
export default Image;

Image.propTypes = {
  src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  alt: PropTypes.string,
  rest: PropTypes.node,
};
