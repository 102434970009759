import React from "react";
import injectSheet from "react-jss";
import Skeleton from "react-loading-skeleton";
import TableSkeleton from "../../components/shared/Table/Table.skeleton";

const SendNotificationSkeleton = (props) => {
  const { classes } = props;
  return (
    <div className={classes.root}>
      <p className={classes.title}>
        <Skeleton />
      </p>
      <div className={classes.wrapper}>
        <div className={classes.selectBox}>
          <Skeleton height="55px" />
        </div>
        <Skeleton width="70px" height="35px" />
      </div>
      <TableSkeleton />
    </div>
  );
};

export const styles = ({ palette, boxShadow, size, typography }) => ({
  root: {
    borderRadius: "8px",
    background: palette.common.white,
    boxShadow: boxShadow.common,
    padding: size.spacing,
    marginBottom: size.spacing,
    "@media(max-width:768px)": {
      padding: size.spacing / 1.5,
    },
  },
  title: {
    fontSize: typography.headingFontSize,
    width: "100px",
    margin: 0,
    marginBottom: size.spacing,
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
    marginBottom: "30px",
    width: "100%",
    "@media(max-width:768px)": {
      flexDirection: "column",
      alignItems: "start",
    },
  },
  selectBox: {
    width: "900px",
    marginRight: "15px",
    "@media(max-width:768px)": { width: "100%", marginBottom: "15px" },
  },
});
export default injectSheet(styles)(SendNotificationSkeleton);
