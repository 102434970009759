import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "./AuthContext";
import jwtDecode from "jwt-decode";
import { setAuthorizationHeader } from "../../../components/utils/utils";
import PropTypes from "prop-types";
import { hasTokenExpired } from "../../helpers/hasTokenExpired";

const LOCAL_STORAGE_KEY = "authToken";

export const AuthContextProvider = (props) => {
  const [loadingUser, setLoadingUser] = useState(true);
  const [user, setUser] = useState({ email: null, token: null });

  const login = (token) => {
    setUser((prev) => ({
      ...prev,
      email: jwtDecode(token).email,
      token: token,
    }));
    setLoadingUser(false);
    setAuthorizationHeader(token);
    localStorage.setItem(LOCAL_STORAGE_KEY, token);
  };

  const logout = () => {
    setUser({ email: null, token: null });
    setLoadingUser(false);
    setAuthorizationHeader();
    localStorage.removeItem(LOCAL_STORAGE_KEY);
  };

  useEffect(() => {
    const token = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (token) {
      if (hasTokenExpired(token)) {
        setUser({ email: null, token: null });
        setLoadingUser(false);
      } else {
        setUser((prev) => ({
          ...prev,
          email: jwtDecode(token).email,
          token: token,
        }));
        setLoadingUser(false);
        setAuthorizationHeader(token);
      }
    }
    setLoadingUser(false);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        loadingUser,
        isAuthenticated: !!user.token,
        login,
        logout,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);

AuthContextProvider.propTypes = {
  children: PropTypes.node,
};
