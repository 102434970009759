import React from "react";
import injectSheet from "react-jss";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useLanguageContext } from "../../../lib/context/LanguageContext/LanguageContext";
import { Link } from "react-router-dom";
import { Icon } from "../Icon/Icon";

const TranslationReminder = (props) => {
  const { translations, classes } = props;
  const { t, i18n } = useTranslation();
  const { changeLanguage } = useLanguageContext();

  const lang = i18n.language;

  const otherLang = lang === "sq" ? "en" : "sq";
  const notTranslatedFields = Object.keys(translations?.[lang]).filter(
    (key) => {
      return !translations?.[otherLang]?.[key];
    }
  );

  if (notTranslatedFields.length === 0) {
    return <></>;
  }

  return (
    <div className={classes.root}>
      <div className={classes.reminderHolder}>
        <Icon icon="warning" className={classes.icon} />
        <span>
          {t("notTranslatedIn", {
            language: lang === "sq" ? t("English") : t("Albanian"),
          })}
        </span>
        <Link to="#" onClick={() => changeLanguage(otherLang)}>
          {t("Translate")}
        </Link>
      </div>
    </div>
  );
};

const styles = ({ palette }) => ({
  root: { display: "inline-block", marginBottom: "10px !important" },
  reminderHolder: {
    textAlign: "center",
    verticalAlign: "middle",
    display: "flex",
    alignItems: "center",
    padding: "5px 10px",
    backgroundColor: "#FBCE07",
    color: palette.common.white,
    borderRadius: "5px",
    fontSize: "0.9rem",
    "& span": {
      marginRight: "5px",
    },

    "& a": {
      color: palette.secondaryTextColor,
      "&:hover": {
        color: "#DD1D21",
      },
    },
  },
  icon: {
    display: "inline-block",
    fill: palette.common.white,
    width: "20px",
    height: "20px",
    marginRight: "5px",
  },
});

export default injectSheet(styles)(TranslationReminder);

TranslationReminder.propTypes = {
  classes: PropTypes.object,
  translations: PropTypes.object.isRequired,
};
