import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ContactForm from "../../components/forms/ContactForm/ContactForm";
import { useContactFormik } from "../../components/forms/ContactForm/lib/useContactFormik";
import { useCardBenefitsFormik } from "../../components/forms/CardBenefitsForm/lib/useCardBenefitsFormik";
import API from "../../api/API";
import CardBenefitsForm from "../../components/forms/CardBenefitsForm/CardBenefitsForm";
import TermsAndPrivacyForm from "../../components/forms/TermsAndPrivacy/TermsAndPrivacyForm";
import { useTermsAndPrivacyFormik } from "../../components/forms/TermsAndPrivacy/lib/useTermsAndPrivacyFormik";

export const ShellInformation = () => {
  const { t, i18n } = useTranslation();
  const [initialValues, setInitialValues] = useState({});

  const [emailError, setEmailError] = useState("");
  const [contactSuccessMessage, setContactSuccessMessage] = useState("");
  const [cardsSuccessMessage, setCardsSuccessMessage] = useState("");
  const [
    termsAndPrivacySuccessMessage,
    setTermsAndPrivacySuccessMessage,
  ] = useState("");

  useEffect(() => {
    getContactInfo();
  }, [i18n.language]);

  const getContactInfo = async () => {
    const contact = await API.contactInfo.getInfo();
    const clubSmartImage = await API.contactInfo.getCardImage(
      "clubSmart_card_benefits"
    );

    const taxiCardImage = await API.contactInfo.getCardImage(
      "taxi_card_benefits"
    );

    const agroCardImage = await API.contactInfo.getCardImage(
      "agro_card_benefits"
    );

    const vipCardImage = await API.contactInfo.getCardImage(
      "vip_card_benefits"
    );

    setInitialValues({
      ...contact,
      clubImg: clubSmartImage,
      taxiImg: taxiCardImage,
      agroImg: agroCardImage,
      vipImg: vipCardImage,
    });
  };

  const contactFormik = useContactFormik({
    initialValues,
    onSubmit: async (values) => {
      try {
        await API.contactInfo.updateInfo(values);
        setContactSuccessMessage(t("contactUpdateSuccess"));
      } catch (e) {
        setEmailError(e.response.data.errors.email);
      }
    },
  });

  const cardBenefitsFormik = useCardBenefitsFormik({
    initialValues,
    onSubmit: async (values, formikHelpers) => {
      const { clubImg, taxiImg, agroImg, vipImg, ...rest } = values;

      const clubImgF = new FormData();
      clubImgF.append("key", "clubSmart_card_benefits");
      clubImgF.append("image", clubImg);

      const taxiImgF = new FormData();
      taxiImgF.append("key", "taxi_card_benefits");
      taxiImgF.append("image", taxiImg);

      const agroImgF = new FormData();
      agroImgF.append("key", "agro_card_benefits");
      agroImgF.append("image", agroImg);

      const vipImgF = new FormData();
      vipImgF.append("key", "vip_card_benefits");
      vipImgF.append("image", vipImg);

      try {
        await API.contactInfo.updateInfo(rest);
        await API.contactInfo.uploadCardImage(clubImgF);
        await API.contactInfo.uploadCardImage(taxiImgF);
        await API.contactInfo.uploadCardImage(agroImgF);
        await API.contactInfo.uploadCardImage(vipImgF);
        setCardsSuccessMessage(t("contactUpdateSuccess"));
      } catch (e) {}
    },
  });

  const termsAndPrivacyFormik = useTermsAndPrivacyFormik({
    initialValues,
    onSubmit: async (values) => {
      await API.contactInfo.updateInfo(values);
      setTermsAndPrivacySuccessMessage(t("contactUpdateSuccess"));
    },
  });
  return (
    <>
      <ContactForm
        formik={contactFormik}
        successMessage={contactSuccessMessage}
        errorMessage={emailError}
      />
      <CardBenefitsForm
        formik={cardBenefitsFormik}
        successMessage={cardsSuccessMessage}
      />
      <TermsAndPrivacyForm
        formik={termsAndPrivacyFormik}
        successMessage={termsAndPrivacySuccessMessage}
      />
    </>
  );
};
