import React from "react";
import { UsersTable } from "../../components/tables/UsersTable/UsersTable";

export const Users = () => {
  return (
    <div>
      <UsersTable />
    </div>
  );
};
