import React from "react";
import cs from "classnames";
import { Editor, RichUtils } from "draft-js";
import injectSheet from "react-jss";
import "draft-js/dist/Draft.css";
import EditorToolbar from "./EditorToolbar";

const TextEditor = (props) => {
  const { classes, label, value, onChange } = props;

  function handleKeyCommand(command, editorState) {
    const newState = RichUtils.handleKeyCommand(editorState, command);

    if (newState) {
      onChange(newState);
      return "handled";
    }

    return "not-handled";
  }

  const toggleBlockType = (blockType) => {
    onChange(RichUtils.toggleBlockType(value, blockType));
  };

  const toggleInlineStyle = (inlineStyle) => {
    onChange(RichUtils.toggleInlineStyle(value, inlineStyle));
  };

  const shouldHidePlaceholder = () => {
    let contentState = value.getCurrentContent();

    if (!contentState.hasText()) {
      if (contentState.getBlockMap().first().getType() !== "unstyled") {
        return true;
      }
    }
    return false;
  };
  return (
    <div
      className={cs(
        classes.root,
        shouldHidePlaceholder() ? classes.hidePlaceholder : undefined
      )}
    >
      <EditorToolbar
        toggleInlineStyle={toggleInlineStyle}
        toggleBlockType={toggleBlockType}
      />
      <Editor
        editorState={value}
        placeholder={label}
        onChange={(editorState) => onChange(editorState)}
        handleKeyCommand={handleKeyCommand}
      />
    </div>
  );
};

const styles = ({ palette, transitions }) => ({
  root: {
    background: palette.activeItem,
    borderRadius: "8px 8px 0 0",
    overflow: "hidden",
    position: "relative",
    color: palette.leadTextColor,
    display: "block",
    fontSize: "1.2rem",
    width: "100%",
    height: "350px",
    boxShadow: "inset 0 -1px 0 #7B8C92",
    transition: transitions.common,
    resize: "vertical",

    "& .public-DraftEditorPlaceholder-root, & .public-DraftEditor-content": {
      paddingTop: "75px",
      paddingLeft: "12px",
    },
    "& .public-DraftEditor-content": {
      overflowY: "auto",
    },
  },
  hidePlaceholder: {
    "& .public-DraftEditorPlaceholder-root": {
      display: "none",
    },
  },
});
export default injectSheet(styles)(TextEditor);
