import React, { useEffect } from "react";
import { Switch, Redirect } from "react-router-dom";
import injectSheet from "react-jss";
import Container from "./layouts/Container";
import SideBar from "./components/SideBar/SideBar";
import Header from "./components/Header/Header";
import Login from "./pages/Login/Login";
import LoadingScreen from "./components/shared/LoadingScreen/LoadingScreen";
import { Routes } from "./routes/Routes";
import { useAuthContext } from "./lib/context/AuthContext/AuthContextProvider";
import { PublicRoute } from "./routes/PublicRoute";
import { useLanguageContext } from "./lib/context/LanguageContext/LanguageContext";
import { useTranslation } from "react-i18next";
import { useRouter } from "./lib/hooks/useRouter";

function App() {
  const { loadingUser } = useAuthContext();
  const { getLngPrefix } = useLanguageContext();
  const lngPrefix = getLngPrefix();
  const { i18n } = useTranslation();
  const router = useRouter();

  // Redirect to login if user is not authenticated and tries to go to an unmatched route from browser address bar
  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (!token) router.history.replace(`${lngPrefix}/login`);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Update language and url when user goes back
  useEffect(() => {
    return router.history.listen((location) => {
      const urlLang = location.pathname.startsWith("/en/") ? "en" : "sq";

      if (urlLang === "en" && i18n.language !== "en") {
        i18n.changeLanguage("en");
      } else if (urlLang === "sq" && i18n.language !== "sq") {
        i18n.changeLanguage("sq");
      }
    });
  }, [router.history, i18n]);

  if (loadingUser)
    return (
      <Root>
        <LoadingScreen />
      </Root>
    );

  return (
    <Root>
      <Switch>
        <Redirect exact from="/" to={`${lngPrefix}/faqs`} />
        <PublicRoute
          component={Login}
          exact
          path={`${lngPrefix}/login`}
          restricted
        />
        <>
          <SideBar />
          <Container>
            <Header />
            <Routes />
          </Container>
        </>
      </Switch>
    </Root>
  );
}

const styles = ({ typography, palette }) => ({
  "@global": {
    "*": {
      boxSizing: "border-box",
      fontFamily: typography.font,
      outline: "none",
      border: "none",
      lineHeight: 1.1,
      "-webkit-tap-highlight-color": "transparent",
    },
    body: {
      margin: 0,
      padding: 0,
    },
  },
  root: {
    height: "100%",
    minHeight: "100vh",
    display: "flex",
    color: palette.leadTextColor,
    background: palette.leadColor,
  },
});

export const Root = injectSheet(styles)((props) => (
  <div className={props.classes.root}>{props.children}</div>
));
export default App;
