import React, { useEffect, useState } from "react";
import injectSheet from "react-jss";
import Table from "../../shared/Table/Table";
import API from "../../../api/API";
import Button from "../../shared/Button/Button";
import { Icon } from "../../shared/Icon/Icon";
import { useTranslation } from "react-i18next";

export const NotificationsTable = (props) => {
  const { classes } = props;
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    API.logs
      .getNotificationLogs()
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const SuccessCircle = <div className={classes.successCircle}> </div>;
  const ErrorCircle = <div className={classes.errorCircle}> </div>;

  const columns = [
    {
      key: "isSuccess",
      header: t("isSuccess"),
    },
    {
      key: "notification_title",
      header: t("notificationTitle"),
    },
    {
      key: "success_failure",
      header: `${t("success")}/${t("failure")}`,
    },
    {
      key: "notification_body",
      header: t("notification"),
    },
    {
      key: "response",
      header: t("response"),
    },
  ];

  const rows = data?.map((notification) => {
    return {
      isSuccess: (
        <span>
          {notification.level === "info" ? SuccessCircle : ErrorCircle}
        </span>
      ),
      notification_title: (
        <span>{notification?.message?.notification.title}</span>
      ),
      success_failure: (
        <span>{`${notification?.response?.success}/${notification?.response?.failure}`}</span>
      ),
      notification_body: (
        <span>{notification?.message?.notification.body}</span>
      ),
      response: (
        <span>
          {notification?.response?.multicast_id &&
            notification.response.multicast_id}
        </span>
      ),
    };
  });

  return (
    <Table
      title="Notifications"
      button={<Button label={<Icon icon="refetch" />} onClick={fetchData} />}
      loading={loading}
      rows={rows}
      columns={columns}
    />
  );
};

const styles = {
  successCircle: {
    marginLeft: "5px",
    width: "20px",
    height: "20px",
    borderRadius: "100px",
    background: "green",
    display: "inline-block",
  },
  errorCircle: {
    marginLeft: "5px",
    width: "20px",
    height: "20px",
    borderRadius: "100px",
    background: "red",
    display: "inline-block",
  },
};

export default injectSheet(styles)(NotificationsTable);
