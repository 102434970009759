import React from "react";
import FAQsTable from "../../components/tables/FAQsTable/FAQsTable";

export const FAQs = () => {
  return (
    <div>
      <FAQsTable />
    </div>
  );
};
