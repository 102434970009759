import React, { useState } from "react";
import injectSheet from "react-jss";
import LoginForm from "../../components/forms/LoginForm/LoginForm";
import { useLoginFormik } from "../../components/forms/LoginForm/lib/useLoginFormik";
import API from "../../api/API";
import logo from "../../assets/icons/shell.svg";
import { useAuthContext } from "../../lib/context/AuthContext/AuthContextProvider";
import { useRouter } from "../../lib/hooks/useRouter";
import { useTranslation } from "react-i18next";

const Login = (props) => {
  const { classes } = props;
  const router = useRouter();
  const { login } = useAuthContext();
  const [error, setError] = useState("");
  const { t } = useTranslation();
  const formik = useLoginFormik({
    onSubmit: (values, formikHelpers) =>
      API.user
        .login(values)
        .then((data) => login(data.token))
        .then(() => router.history.push("/"))
        .catch(() => {
          setError(t("formValidations.loginError"));
          formikHelpers.resetForm();
        }),
  });

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.logo}>
          <img src={logo} alt="shell-logo" />
        </div>
        <p className={classes.title}>{t("login").toUpperCase()}</p>
        <LoginForm formik={formik} error={error} />
      </div>
    </div>
  );
};
const styles = ({ palette, size, typography, boxShadow }) => ({
  root: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  container: {
    borderRadius: "8px",
    margin: size.spacing,
    width: "480px",
    background: palette.common.white,
    padding: size.spacing + 30,
    boxShadow: boxShadow.common,
    "@media (max-width:768px)": {
      margin: size.spacing / 2,
      padding: size.spacing,
    },
  },
  logo: { textAlign: "center" },
  title: {
    fontSize: typography.headingFontSize,
    textAlign: "center",
    fontWeight: typography.weight.medium,
  },
});
export default injectSheet(styles)(Login);
