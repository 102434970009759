import axios from "axios";
import { API_URL } from "../config";
import i18n from "i18next";

const userConfig = {
  headers: {
    "Content-Type": "application/json; charset=utf-8",
  },
};

const formConfig = {
  headers: { "Content-Type": "multipart/form-data", Accept: "*/*" },
};

export default {
  user: {
    login: (userCredentials) =>
      axios
        .post(
          `${API_URL + i18n.language}/user/login`,
          userCredentials,
          userConfig
        )
        .then((response) => response.data),

    signup: (user) =>
      axios.post(`${API_URL + i18n.language}en/user/signup`, user, userConfig),

    getAllUsers: () =>
      axios
        .get(`${API_URL + i18n.language}en/user/getAll`)
        .then((response) => response.data),
  },

  products: {
    getAllProducts: () =>
      axios
        .get(`${API_URL + i18n.language}/products`)
        .then((response) => response.data.products),

    getProduct: (id) =>
      axios
        .get(`${API_URL + i18n.language}/products/${id}`)
        .then((response) => response.data),

    addProduct: (product) =>
      axios.post(`${API_URL + i18n.language}/products`, product, formConfig),

    deleteProduct: (id) =>
      axios.delete(`${API_URL + i18n.language}/products/${id}`),

    updateProduct: (id, product) =>
      axios.patch(
        `${API_URL + i18n.language}/products/${id}`,
        product,
        formConfig
      ),
  },

  faqs: {
    getAllFAQs: () =>
      axios
        .get(`${API_URL + i18n.language}/faqs`)
        .then((response) => response.data.faqs),

    getFAQ: (id) =>
      axios
        .get(`${API_URL + i18n.language}/faqs/${id}`)
        .then((response) => response.data),

    addFAQ: (faq) => axios.post(`${API_URL + i18n.language}/faqs`, faq),

    updateFAQ: (id, faq) =>
      axios.patch(`${API_URL + i18n.language}/faqs/${id}`, faq),

    deleteFAQ: (id) => axios.delete(`${API_URL + i18n.language}/faqs/${id}`),
  },

  category: {
    getAllCategories: () =>
      axios
        .get(`${API_URL + i18n.language}/category`)
        .then((response) => response.data.categories),

    getCategory: (id) =>
      axios
        .get(`${API_URL + i18n.language}/category/${id}`)
        .then((response) => response.data),

    addCategory: (category) =>
      axios.post(`${API_URL + i18n.language}/category`, category, formConfig),

    updateCategory: (id, category) =>
      axios.patch(
        `${API_URL + i18n.language}/category/${id}`,
        category,
        formConfig
      ),

    deleteCategory: (id) =>
      axios.delete(`${API_URL + i18n.language}/category/${id}`),
  },

  transactionRatings: {
    getTransactionRatings: ({ page, limit }) =>
      axios
        .get(`${API_URL}api/transaction-rating/get`, {
          params: {
            page: parseInt(page) || 1,
            limit: parseInt(limit) || 10,
          },
        })
        .then((response) => response.data.transactionsRatings),
  },

  offers: {
    getAllOffers: () =>
      axios
        .get(`${API_URL + i18n.language}/offers`)
        .then((response) => response.data.offers),

    getOffer: (id) =>
      axios
        .get(`${API_URL + i18n.language}/offers/${id}`)
        .then((response) => response.data),

    addOffer: (offer) =>
      axios.post(`${API_URL + i18n.language}/offers`, offer, formConfig),

    updateOffer: (id, offer) =>
      axios.patch(`${API_URL + i18n.language}/offers/${id}`, offer, formConfig),

    deleteOffer: (id) =>
      axios.delete(`${API_URL + i18n.language}/offers/${id}`),
  },

  contactInfo: {
    getInfo: () =>
      axios
        .get(`${API_URL + i18n.language}/api/shell-information/get`)
        .then((response) => response.data.data),
    updateInfo: (info) =>
      axios.put(
        `${API_URL + i18n.language}/api/shell-information/update`,
        info
      ),
    getCardImage: (key) =>
      axios
        .get(`${API_URL + i18n.language}/api/card-type-images/get-image`, {
          params: { key },
        })
        .then((res) => res.data.image),
    uploadCardImage: (data) =>
      axios.post(
        `${API_URL + i18n.language}/api/card-type-images/upload-image`,
        data
      ),
  },
  logs: {
    getSmsLogs: () =>
      axios
        .get(`${API_URL}api/logs/get_sms_logs`)
        .then((response) => response.data),
    getNotificationLogs: () =>
      axios
        .get(`${API_URL}api/logs/get_notification_logs`)
        .then((response) => response.data.logs),
    getVerificationCodeLogs: () =>
      axios
        .get(`${API_URL}api/logs/get_verificationCode_logs`)
        .then((response) => response.data.logs),
  },
  notification: {
    sendNotification: (offerId) =>
      axios.post(`${API_URL}api/webhooks/on-send-offer-notification`, {
        offerId,
      }),
    getLastTen: () =>
      axios
        .get(`${API_URL + i18n.language}/offers/sent_as_notifications`)
        .then((response) => response.data.offers),
  },
  activateUser: (cardNumber) =>
    axios.post(`${API_URL}api/test/activate_user`, {
      cardNumber,
    }),
  getLocales: () =>
    axios.get(`${API_URL}get_labels`).then((response) => response.data),
};
