import React from "react";
import injectSheet from "react-jss";
import Input from "../../shared/Input/Input";
import Button from "../../shared/Button/Button";
import Image from "../../shared/Image/Image";
import { useUIContext } from "../../../lib/context/UIContext/UIContext";
import { Prompt } from "react-router";
import { useTranslation } from "react-i18next";
import DropDown from "../../shared/DropDown/DropDown";
import FileInput from "../../shared/FileInput/FileInput";
import PropTypes from "prop-types";

const ProductForm = (props) => {
  const { classes, formik, categories, editMode } = props;
  const { isDesktop } = useUIContext();
  const { t } = useTranslation();
  return (
    <form
      onSubmit={formik.handleSubmit}
      autoComplete="off"
      className={classes.root}
    >
      <div className={classes.fieldGroup}>
        <Prompt when={formik.dirty} message={t("onLeaveMessage")} />
        <Input
          label={t("nameForDashboard")}
          name="name"
          id="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={!!formik.errors.name && formik.touched.name}
          helperText={
            formik.errors.name && formik.touched.name && formik.errors.name
          }
        />
        <Input
          label={t("description")}
          name="description"
          id="description"
          multiLine
          value={formik.values.description}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={!!formik.errors.description && formik.touched.description}
          helperText={
            formik.errors.description &&
            formik.touched.description &&
            formik.errors.description
          }
        />
        <DropDown
          mBottom
          label={t("selectCategory")}
          options={categories}
          selectedOption={formik.values.category}
          onSelect={(selectedOption) =>
            formik.setFieldValue("category", selectedOption)
          }
          onBlur={formik.handleBlur}
          setInputValue={(setFilter) => setFilter("")}

          // error={!!formik.errors.category}
          // helperText={formik.errors.category && formik.errors.category.label}
        />

        <FileInput
          onChange={(file) => formik.setFieldValue("img", file)}
          onBlur={formik.handleBlur}
          error={formik.errors.img && formik.touched.img && formik.errors.img}
        />
        <div className={classes.priceField}>
          <Input
            label={t("price")}
            name="price"
            id="price"
            value={formik.values.price}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={!!formik.errors.price && formik.touched.price}
            helperText={
              formik.errors.price && formik.touched.price && formik.errors.price
            }
          />
        </div>
        {!isDesktop && (
          <Image
            src={formik.values.img}
            alt="Product"
            style={{
              objectFit: "cover",
              width: "100%",
              height: "150px",
              marginBottom: "30px",
            }}
          />
        )}

        <Button
          type="submit"
          label={editMode ? t("edit") : t("addProduct")}
          disabled={!formik.dirty}
          loading={formik.isSubmitting}
        />
      </div>
      {isDesktop && (
        <Image
          alt="Product"
          src={formik.values.img}
          style={{ objectFit: "cover", width: "250px", height: "250px" }}
        />
      )}
    </form>
  );
};

const styles = {
  root: {
    display: "flex",
    justifyContent: "space-between",
  },
  fieldGroup: {
    width: "900px",
    maxWidth: "900px",
    position: "relative",
    marginRight: "20px",
    "& > *": {
      marginBottom: "40px",
    },
    "& :last-child": {
      marginBottom: 0,
    },
  },
  priceField: { width: "80px" },
};
export default injectSheet(styles)(ProductForm);

ProductForm.defaultProps = {
  categories: [],
};

ProductForm.propTypes = {
  classes: PropTypes.object,
  formik: PropTypes.object.isRequired,
  categories: PropTypes.arrayOf(PropTypes.object),
};
