import React, { useEffect, useState } from "react";
import ProductForm from "../../components/forms/ProductForm/ProductForm";
import { useProductFormik } from "../../components/forms/ProductForm/lib/useProductFormik";
import api from "../../api/API";
import FormLayout from "../../layouts/PageLayout";
import { useTranslation } from "react-i18next";
import { useLanguageContext } from "../../lib/context/LanguageContext/LanguageContext";
import API from "../../api/API";

export const AddProduct = (props) => {
  const { t, i18n } = useTranslation();
  const { getLngPrefix } = useLanguageContext();
  const [categories, setCategories] = useState([]);
  const lang = i18n.language;

  useEffect(() => {
    API.category.getAllCategories().then((data) => {
      setCategories(
        data.map((category) => ({
          label: category.category_info.name,
          id: category._id,
        }))
      );
    });
  }, [lang]);

  const formik = useProductFormik({
    onSubmit: async (values, formikHelpers) => {
      let formData = new FormData();
      formData.append("name", values.name);
      formData.append("description", values.description);
      formData.append("productImage", values.img);
      formData.append("price", values.price);
      formData.append("categoryId", values.category.id);

      try {
        await api.products.addProduct(formData);
        formikHelpers.resetForm({ values: { ...values } });
        props.history.push(`${getLngPrefix()}/products`);
      } catch (e) {}
    },
  });

  return (
    <FormLayout title={t("addProduct")}>
      {/*<DropDown label="Select category" options={categories} />*/}
      <ProductForm formik={formik} categories={categories} />
    </FormLayout>
  );
};
