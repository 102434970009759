import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

export const useProductFormik = (props) => {
  const { t } = useTranslation();

  const productSchema = Yup.object().shape({
    name: Yup.string().required(t("formValidations.name")),
    description: Yup.string().required(t("formValidations.description")),
    category: Yup.object().shape({
      label: Yup.string().required(t("formValidations.category")),
      id: Yup.string(),
    }),
    price: Yup.number()
      .required(t("formValidations.price"))
      .typeError(t("formValidations.priceValue")),
    img: Yup.string().required(t("formValidations.image")),
  });
  return useFormik({
    initialValues: {
      name: "",
      description: "",
      category: { label: "", id: "" },
      price: "",
      img: "",
      ...props?.initialValues,
    },
    enableReinitialize: true,
    validationSchema: productSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: async (values, formikHelpers) => {
      await props.onSubmit(values, formikHelpers);
    },
  });
};

useProductFormik.propTypes = {
  props: PropTypes.shape({
    initialValues: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
  }),
};
