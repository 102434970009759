import React from "react";
import { useTranslation } from "react-i18next";
import { convertToHTML } from "draft-convert";
import FAQForm from "../../components/forms/FAQForm/FAQForm";
import { useFAQFormik } from "../../components/forms/FAQForm/lib/useFAQFormik";
import API from "../../api/API";
import FormLayout from "../../layouts/PageLayout";
import { useLanguageContext } from "../../lib/context/LanguageContext/LanguageContext";

export const AddFAQ = (props) => {
  const { t } = useTranslation();
  const { getLngPrefix } = useLanguageContext();
  const formik = useFAQFormik({
    onSubmit: async (values, formikHelpers) => {
      const descriptionConvertedToHTML = convertToHTML(
        values.description.getCurrentContent()
      );

      try {
        await API.faqs.addFAQ({
          ...values,
          description: descriptionConvertedToHTML,
        });
        formikHelpers.resetForm({ values: { ...values } });
        props.history.push(`${getLngPrefix()}/faqs`);
      } catch (e) {}
    },
  });
  return (
    <FormLayout title={t("addFAQ")}>
      <FAQForm formik={formik} />
    </FormLayout>
  );
};
