import React, { useEffect, useState } from "react";
import injectSheet from "react-jss";
import { orderBy } from "lodash";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Icon } from "../Icon/Icon";
import cs from "classnames";
import HandleLoading from "../HandleLoading/HandleLoading";
import HandleError from "../HandleError/HandleError";
import TableLoadingSkeleton from "./Table.skeleton";

const Table = (props) => {
  const { classes } = props;
  const { title, loading, error, button } = props;
  const { t } = useTranslation();

  const [columns, setColumns] = useState(props.columns);
  const [rows, setRows] = useState(props.rows);

  const [sortKey, setSortKey] = useState("");
  const [sortType, setSortType] = useState("desc");

  useEffect(() => {
    setRows(props.rows);
    setColumns(props.columns);
  }, [props.rows, props.columns, button]);

  //Will sort table cols asc or desc
  const sortByCol = (colKey) => {
    const type = sortType === "asc" ? "desc" : "asc";
    setSortKey(colKey);
    setSortType(type);
    const sortedRows = orderBy(
      rows,
      (e) => {
        const row = e[colKey];
        if (typeof row === "object") {
          return row.props && row.props["data-sort"]
            ? row.props["data-sort"]
            : null;
        }
        return e[colKey];
      },
      [type]
    );
    setRows(sortedRows);
  };

  return (
    <div className={classes.root}>
      <HandleLoading
        loading={loading}
        loadingPlaceholder={<TableLoadingSkeleton />}
      >
        <HandleError error={error}>
          <div className={classes.header}>
            <div className={classes.title}>{title}</div>
            {button && button}
          </div>
          <div className={classes.tableWrapper}>
            <table className={classes.table}>
              <thead>
                <tr>
                  {columns.map((col, idx) => (
                    <th key={idx}>
                      {col.header}
                      {col.sortable && (
                        <button
                          className={cs(
                            classes.filter,
                            sortKey === col.key &&
                              sortType === "asc" &&
                              classes.filterAsc
                          )}
                          onClick={() => sortByCol(col.key)}
                        >
                          <Icon icon="sort" />
                        </button>
                      )}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {rows.length === 0 ? (
                  <td colSpan={columns.length}>{t("noData")}</td>
                ) : (
                  rows.map((row, index) => (
                    <tr key={index}>
                      {columns.map((rowColumn, idx) => (
                        <td key={idx}>{row[rowColumn["key"]]}</td>
                      ))}
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </HandleError>
      </HandleLoading>
    </div>
  );
};

const styles = ({ size, typography, palette, transitions, boxShadow }) => ({
  root: {
    borderRadius: "8px 8px 0 0",
    background: palette.common.white,
    color: palette.leadTextColor,
    boxShadow: boxShadow.common,
    marginBottom: size.spacing,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    padding: {
      top: size.spacing - 10,
      left: size.spacing,
      right: size.spacing,
      bottom: size.spacing / 2,
    },
    "@media(max-width:768px)": { paddingRight: size.spacing / 2 },
  },
  filter: {
    background: "none",
    color: palette.leadTextColor,
    verticalAlign: "inherit",
    transition: transitions.common,
    cursor: "pointer",
  },

  title: {
    fontWeight: typography.weight.medium,
    fontSize: typography.headingFontSize,
    margin: "auto 0",
  },
  tableWrapper: {
    overflow: "auto",
  },
  table: {
    width: "100%",
    textAlign: "left",
    borderCollapse: "collapse",
    fontSize: typography.defaultFontSize,

    "@media (max-width:768px)": {
      minWidth: "1200px",
    },

    "& td,th": {
      padding: {
        top: "10px",
        bottom: "10px",
        left: size.spacing,
        right: size.spacing,
      },
    },
    "& th": {
      fontWeight: typography.weight.medium,
      whiteSpace: "nowrap",
    },

    "& td": {
      fontWeight: typography.weight.normal,
    },
    "& td:last-child": {
      textAlign: "center",
    },
    "& th:last-child": {
      textAlign: "center",
    },
    "& tbody > tr:nth-child(odd)": {
      background: palette.activeItem,
      borderTop: "1px solid #EFEFEF",
      borderBottom: "1px solid #EFEFEF",
    },
  },

  filterDesc: {
    transform: "rotateZ(180) rotateY(180)",
  },
  filterAsc: {
    transition: transitions.common,
    transform: "rotateZ(180deg) rotateY(180deg)",
  },
});

export default injectSheet(styles)(Table);

Table.defaultProps = {
  columns: [],
  rows: [],
};

Table.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  loading: PropTypes.bool,
  error: PropTypes.string,
  button: PropTypes.element,
};
