import React, { useEffect, useState } from "react";
import injectSheet from "react-jss";
import Table from "../../shared/Table/Table";
import API from "../../../api/API";
import Button from "../../shared/Button/Button";
import { useRouter } from "../../../lib/hooks/useRouter";
import { useConfirm } from "../../../lib/context/ConfirmContext/ConfirmContext";
import Image from "../../shared/Image/Image";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

const ProductsTable = (props) => {
  const router = useRouter();
  const { confirm } = useConfirm();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { t, i18n } = useTranslation();
  const [deleting, setDeleting] = useState(null);
  const { classes } = props;
  const lang = i18n.language;

  useEffect(() => {
    setLoading(true);
    API.products
      .getAllProducts()
      .then((data) => {
        setProducts(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setError(err.toString());
      });
  }, [lang]);

  const handleDelete = async (id) => {
    try {
      await confirm();
      setDeleting(id);
      await API.products.deleteProduct(id);
      setProducts((prevState) => prevState.filter((item) => item._id !== id));
      setDeleting(null);
    } catch (e) {
      setDeleting(null);
    }
  };

  const columns = [
    {
      key: "products_image",
      header: t("image"),
    },
    {
      key: "products_name",
      header: t("nameForDashboard"),
      sortable: true,
    },
    // {
    //   key: "products_description",
    //   header: t("description"),
    //   sortable: true,
    // },
    {
      key: "products_price",
      header: t("price"),
      sortable: true,
    },
    {
      key: "products_actions",
      header: t("actions"),
    },
  ];

  const rows = products.map((product) => ({
    products_image: (
      <div className={classes.wrapper}>
        <div className={classes.imgThumb}>
          <Image
            src={product.productImage}
            alt={product.data.name}
            className={classes.img}
          />
        </div>
      </div>
    ),
    products_name: (
      <span data-sort={product.data.name}>
        {product.data.name || (
          <span className={classes.warningText}>{t("noName")}</span>
        )}
      </span>
    ),
    // products_description: (
    //   <span data-sort={product.data.description}>
    //     {product.data.description}
    //   </span>
    // ),
    products_price: <span data-sort={product.price}>{product.price}</span>,
    products_actions: (
      <div className={classes.buttonsWrapper}>
        <Button
          type="danger"
          label={t("delete")}
          onClick={() => handleDelete(product._id)}
          loading={deleting === product._id}
        />
        <Button
          label={t("edit")}
          onClick={() => router.history.push(`products/edit/${product._id}`)}
        />
      </div>
    ),
  }));

  return (
    <Table
      title={t("products")}
      columns={columns}
      rows={rows}
      loading={loading}
      error={error}
      button={
        <Button
          label={t("addProduct")}
          onClick={() => router.history.push("products/add")}
        />
      }
    />
  );
};

const styles = ({ palette, boxShadow }) => ({
  wrapper: {
    position: "relative",
  },
  buttonsWrapper: { display: "flex", justifyContent: "center" },

  imgThumb: {
    backgroundColor: "lightgray",
    width: "40px",
    height: "40px",
    borderRadius: "100px",
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  img: {
    objectFit: "cover",
    height: "40px",
    "&:hover ": {
      position: "absolute",
      zIndex: 200,
      left: 0,
      bottom: 0,
      height: "150px",
      borderRadius: "5px",
      boxShadow: boxShadow.common,
    },
  },
  warningText: {
    color: palette.button.warning,
    fontSize: "1rem",
  },
});

export default injectSheet(styles)(ProductsTable);

ProductsTable.propTypes = {
  classes: PropTypes.object,
};
