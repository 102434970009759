import React from "react";
import injectSheet from "react-jss";
import { Icon } from "../shared/Icon/Icon";
import { useUIContext } from "../../lib/context/UIContext/UIContext";
import { useRouter } from "../../lib/hooks/useRouter";
import { useLanguageContext } from "../../lib/context/LanguageContext/LanguageContext";

const styles = ({ size, palette, transitions }) => ({
  root: {
    height: size.header.height,
    display: "flex",
    padding: { left: "40px", right: "20px" },
    justifyContent: "space-between",
    alignItems: "center",
    transition: transitions.common,
  },
  icon: {
    cursor: "pointer",
    color: palette.secondaryTextColor,
    width: size.header.iconSize,
    height: size.header.iconSize,
  },
  logo: {
    cursor: "pointer",
    height: "70px",
    width: "70px",
  },
});

const SideBarHeader = (props) => {
  const { setSidebarOpen, isDesktop } = useUIContext();
  const { getLngPrefix } = useLanguageContext();
  const router = useRouter();
  const { classes } = props;
  return (
    <div className={classes.root}>
      <Icon
        icon="shell"
        className={classes.logo}
        onClick={() => router.history.push(`${getLngPrefix()}/products`)}
      />
      {!isDesktop && (
        <div onClick={() => setSidebarOpen(false)}>
          <Icon icon="menu_open" className={classes.icon} />
        </div>
      )}
    </div>
  );
};

export default injectSheet(styles)(SideBarHeader);
