import { useFormik } from "formik";
import { EditorState } from "draft-js";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

const initialState = EditorState.createEmpty("");

export const useFAQFormik = (props) => {
  const { t } = useTranslation();
  const faqSchema = Yup.object().shape({
    title: Yup.string().required(t("formValidations.title")),
    description: Yup.object().required(t("formValidations.description")),
  });

  return useFormik({
    initialValues: {
      title: "",
      description: initialState,
      ...props.initialValues,
    },
    enableReinitialize: true,
    validationSchema: faqSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: async (values, formikHelpers) => {
      await props.onSubmit(values, formikHelpers);
    },
  });
};

useFAQFormik.propTypes = {
  props: PropTypes.shape({
    initialValues: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
  }),
};
