import React, { useEffect, useRef, useState } from "react";
import injectSheet from "react-jss";
import cs from "classnames";
import Input from "../Input/Input";
import { useOutsideClickDetection } from "../../../lib/hooks/useOutsideClickDetection";
import PropTypes from "prop-types";
import { Icon } from "../Icon/Icon";

const DropDown = (props) => {
  const ddRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);
  const [filter, setFilter] = useState("");

  const {
    classes,
    options,
    onBlur,
    selectedOption,
    onSelect,
    mBottom,
    label,
    error,
    helperText,
    disabled,
    setInputValue,
  } = props;

  useEffect(() => {
    // if (selectedOption.id === "") setFilter("");
    if (selectedOption.label) setFilter(selectedOption.label);
  }, [selectedOption]);

  useEffect(() => {
    if (selectedOption.id === "") setInputValue(setFilter);
  }, [selectedOption.label]); // eslint-disable-line react-hooks/exhaustive-deps

  useOutsideClickDetection(ddRef, handleClickOutside);

  const toggleDd = () => setIsOpen((prevState) => !prevState);

  function handleClickOutside() {
    setIsOpen(false);
  }

  const handleItemClick = (option) => {
    setIsOpen(false);
    onSelect(option);
    setFilter(option.label);
  };

  const handleChange = (e) => {
    if (filter === "") onSelect({ label: "", id: "" });
    setIsOpen(true);
    setFilter(e.target.value);
  };

  const filteredOptions = (filter) => {
    if (filter === "") return options;
    if (selectedOption.id) return options;

    return options?.filter(
      (offer) => offer.label?.toLowerCase().includes(filter?.toLowerCase()) //Convert label and filter to lowercase before filtering
    );
  };

  return (
    <div className={cs(classes.root, mBottom && classes.mBottom)} ref={ddRef}>
      <Input
        id="dropdown"
        onClick={toggleDd}
        label={label}
        value={filter}
        onChange={(e) => handleChange(e)}
        onBlur={onBlur}
        autoComplete="off"
        error={error}
        helperText={helperText}
        disabled={disabled}
      />
      <Icon
        icon="down-arrow"
        className={cs(
          classes.icon,
          isOpen && classes.rotateArrow,
          disabled && classes.disabled
        )}
      />
      <div className={classes.listContainer}>
        {isOpen && (
          <ul className={classes.list}>
            {filteredOptions(filter).map((option) => (
              <li
                key={option.id}
                className={cs(
                  classes.listItem,
                  selectedOption?.id === option.id && classes.activeItem
                )}
                onClick={() => handleItemClick(option)}
              >
                {option.label}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

const styles = ({ palette, boxShadow, transitions, disabled }) => ({
  root: {
    width: "100%",
    maxWidth: "900px",
    position: "relative",
    transitions: transitions.common,
  },

  listContainer: {
    position: "absolute",
    zIndex: "100",
    width: "100%",
    maxWidth: "900px",
    background: "#fff",
    boxShadow: boxShadow.common,
    maxHeight: "500px",
    overflow: "auto",
  },
  list: { margin: 0, padding: 0 },
  mBottom: { marginBottom: "20px" },
  listItem: {
    listStyle: "none",
    padding: "15px 15px",
    "&:hover ": {
      background: palette.activeItem,
    },
  },
  activeItem: { background: palette.activeItem },

  icon: {
    width: "15px",
    height: "15px",
    position: "absolute",
    right: 15,
    top: 21,
    transition: transitions.common,
  },

  rotateArrow: {
    transform: "scaleY(-1)",
  },
  disabled,
});

export default injectSheet(styles)(DropDown);

DropDown.propTypes = {
  classes: PropTypes.object,
  options: PropTypes.arrayOf(PropTypes.object),
  selectedOption: PropTypes.object,
  onSelect: PropTypes.func,
  mBottom: PropTypes.bool,
};
