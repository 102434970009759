import React, { useState } from "react";
import UserForm from "../../components/forms/UserForm/UserForm";
import { useUserFormik } from "../../components/forms/UserForm/lib/useUserFormik";
import FormLayout from "../../layouts/PageLayout";
import API from "../../api/API";

export const AddUser = (props) => {
  const [submitting, setSubmitting] = useState(false);
  const formik = useUserFormik({
    onSubmit: async (values) => {
      setSubmitting(true);
      console.log();
      API.user
        .signup(JSON.stringify(values))
        .then(() => {
          props.history.push("/users");
          setSubmitting(false);
        })
        .catch(() => {
          setSubmitting(false);
        });
    },
  });
  return (
    <FormLayout title="ADD USER">
      <UserForm formik={formik} submitting={submitting} />
    </FormLayout>
  );
};
