import React from "react";
import ProductsTable from "../../components/tables/ProductsTable/ProductsTable";

export const Products = () => {
  return (
    <div>
      <ProductsTable />
    </div>
  );
};
