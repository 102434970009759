import React from "react";
import CategoriesTable from "../../components/tables/CategoriesTable/CategoriesTable";

export const Categories = () => {
  return (
    <div>
      <CategoriesTable />
    </div>
  );
};
