import React from "react";
import injectSheet from "react-jss";
import Input from "../../shared/Input/Input";
import { useTranslation } from "react-i18next";
import Button from "../../shared/Button/Button";
import PropTypes from "prop-types";
import FormLayout from "../../../layouts/PageLayout";
import FileInput from "../../shared/FileInput/FileInput";
import Image from "../../shared/Image/Image";
const ContactForm = (props) => {
  const { classes, formik, successMessage } = props;
  const { t } = useTranslation();

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormLayout title={t("cardBenefits")}>
        <div className={classes.fieldGroup}>
          <span className={classes.wrapper}>
            <Input
              multiLine
              label="Club Smart"
              id="clubSmart_card_benefits"
              value={formik.values.clubSmart_card_benefits}
              onChange={formik.handleChange}
            />
            <Image
              src={formik.values.clubImg}
              style={{
                marginLeft: "30px",
                objectFit: "cover",
                height: "200px",
                width: "310px",
              }}
            />
          </span>
          <FileInput
            onChange={(file) => formik.setFieldValue("clubImg", file, true)}
            onBlur={formik.handleBlur}
          />
          <span className={classes.wrapper}>
            <Input
              multiLine
              label="Taxi Card"
              id="taxi_card_benefits"
              value={formik.values.taxi_card_benefits}
              onChange={formik.handleChange}
            />
            <Image
              src={formik.values.taxiImg}
              style={{
                marginLeft: "30px",
                objectFit: "cover",
                height: "200px",
                width: "310px",
              }}
            />
          </span>
          <FileInput
            onChange={(file) => formik.setFieldValue("taxiImg", file, true)}
          />
          <span className={classes.wrapper}>
            <Input
              multiLine
              label="Agro Card"
              value={formik.values.agro_card_benefits}
              id="agro_card_benefits"
              onChange={formik.handleChange}
            />
            <Image
              src={formik.values.agroImg}
              style={{
                marginLeft: "30px",
                objectFit: "cover",
                width: "310px",
                height: "200px",
              }}
            />
          </span>
          <FileInput
            onChange={(file) => formik.setFieldValue("agroImg", file, true)}
          />
          <span className={classes.wrapper}>
            <Input
              multiLine
              label="VIP Card"
              value={formik.values.vip_card_benefits}
              id="vip_card_benefits"
              onChange={formik.handleChange}
            />
            <Image
              src={formik.values.vipImg}
              style={{
                marginLeft: "30px",
                objectFit: "cover",
                height: "200px",
                width: "310px",
              }}
            />
          </span>
          <FileInput
            onChange={(file) => formik.setFieldValue("vipImg", file, true)}
          />

          <Button
            label={t("update")}
            type="submit"
            disabled={!formik.dirty}
            loading={formik.isSubmitting}
          />
        </div>
        <p className={classes.successMessage}>{successMessage}</p>
      </FormLayout>
    </form>
  );
};

const styles = ({ palette, typography }) => ({
  fieldGroup: {
    marginRight: "20px",
    "& > *": {
      marginBottom: "40px",
    },
    "& :last-child": {
      marginBottom: 0,
    },
  },

  wrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  successMessage: {
    marginTop: "30px",
    fontWeight: typography.weight.medium,
    color: palette.button.success,
  },
  errorMessage: {
    color: palette.button.danger,
  },
  cardBenefits: {
    fontWeight: typography.weight.medium,
    fontSize: typography.headingFontSize,
  },
});

export default injectSheet(styles)(ContactForm);

ContactForm.propTypes = {
  classes: PropTypes.object,
  formik: PropTypes.object.isRequired,
  successMessage: PropTypes.string,
};
