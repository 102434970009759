import { useFormik } from "formik";
import * as Yup from "yup";
import PropTypes from "prop-types";

export const useCardBenefitsFormik = (props) => {
  const contactSchema = Yup.object().shape({
    clubSmart_card_benefits: Yup.string(),
    taxi_card_benefits: Yup.string(),
    agro_card_benefits: Yup.string(),
    vip_card_benefits: Yup.string(),
  });

  return useFormik({
    initialValues: {
      clubSmart_card_benefits: "",
      clubImg: "",
      taxi_card_benefits: "",
      taxiImg: "",
      agro_card_benefits: "",
      agroImg: "",
      vip_card_benefits: "",
      vipImg: "",
      ...props.initialValues,
    },
    enableReinitialize: true,
    validationSchema: contactSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values, formikHelpers) => {
      await props.onSubmit(values, formikHelpers);
    },
  });
};

useCardBenefitsFormik.propTypes = {
  props: PropTypes.shape({
    initialValues: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
  }),
};
