import React from "react";
import injectSheet from "react-jss";
import { NavLink } from "react-router-dom";
import cs from "classnames";

const StyledLink = (props) => {
  const { classes, label, to, className, activeClassName, onClick } = props;
  return (
    <NavLink
      to={to}
      className={cs(classes.root, className)}
      activeClassName={cs(classes.activeLink, activeClassName)}
      onClick={onClick}
    >
      {label}
    </NavLink>
  );
};

const styles = ({ palette }) => ({
  root: {
    background: palette.button.normal,
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    height: "40px",
    padding: "0 14px",
    cursor: "pointer",
    textAlign: "center",
    fontSize: "15px",
    color: "#fff",
    borderRadius: "100px",
    margin: "0 5px",

    "&:hover": {
      background: palette.button.normalHover,
    },
  },
  activeLink: {
    backgroundColor: "#0495d6",
  },
});
export default injectSheet(styles)(StyledLink);
