import React from "react";
import { Products } from "../pages/Products/Products";
import { FAQs } from "../pages/FAQs/FAQ";
import { Offers } from "../pages/Offers/Offers";
import { Categories } from "../pages/Categories/Categories";
import { AddProduct } from "../pages/Products/AddProduct";
import { AddFAQ } from "../pages/FAQs/AddFAQ";
import { AddCategory } from "../pages/Categories/AddCategory";
import { EditProduct } from "../pages/Products/EditProduct";
import { EditCategory } from "../pages/Categories/EditCategory";
import { EditFAQ } from "../pages/FAQs/EditFAQ";
import { Users } from "../pages/Users/Users";
import { AddUser } from "../pages/Users/AddUser";
import { AddOffer } from "../pages/Offers/AddOffer";
import { EditOffer } from "../pages/Offers/EditOffer";
import { useLanguageContext } from "../lib/context/LanguageContext/LanguageContext";
import { ShellInformation } from "../pages/ShellInformation/ShellInformation";
import { PublicRoute } from "./PublicRoute";
import { PrivateRoute } from "./PrivateRoute";
import SendNotification from "../pages/Offers/SendNotification";
import Logs from "../pages/Logs/Logs";
import { TransactionRatings } from "../pages/TransactionRatings/TransactionRatings";

const RouteType = {
  PUBLIC: "PUBLIC",
  PRIVATE: "PRIVATE",
  RESTRICTED: "RESTRICTED",
};
export const routes = [
  // {
  //   type: RouteType.RESTRICTED,
  //   path: "/login",
  //   exact: true,
  //   component: Login,
  //   title: "Login",
  // },
  {
    type: RouteType.PRIVATE,
    path: "/logs",
    // exact: true,
    component: Logs,
  },
  {
    type: RouteType.PRIVATE,
    path: "/products",
    exact: true,
    component: Products,
  },
  {
    type: RouteType.PRIVATE,
    path: "/faqs",
    exact: true,
    component: FAQs,
  },
  {
    type: RouteType.PRIVATE,
    path: "/offers",
    exact: true,
    component: Offers,
  },
  {
    type: RouteType.PRIVATE,
    path: "/categories",
    exact: true,
    component: Categories,
  },
  {
    type: RouteType.PRIVATE,
    path: "/transaction-ratings",
    exact: true,
    component: TransactionRatings,
  },
  {
    type: RouteType.PRIVATE,
    path: "/users",
    exact: true,
    component: Users,
  },
  {
    type: RouteType.PRIVATE,
    path: "/products/add",
    exact: true,
    component: AddProduct,
  },
  {
    type: RouteType.PRIVATE,
    path: "/faqs/add",
    exact: true,
    component: AddFAQ,
  },
  {
    type: RouteType.PRIVATE,
    path: "/offers/add",
    exact: true,
    component: AddOffer,
  },
  {
    type: RouteType.PRIVATE,
    path: "/categories/add",
    exact: true,
    component: AddCategory,
  },
  {
    type: RouteType.PRIVATE,
    path: "/users/add",
    exact: true,
    component: AddUser,
  },
  {
    type: RouteType.PRIVATE,
    path: "/products/edit/:id",
    exact: true,
    component: EditProduct,
  },
  {
    type: RouteType.PRIVATE,
    path: "/category/edit/:id",
    exact: true,
    component: EditCategory,
  },
  {
    type: RouteType.PRIVATE,
    path: "/faqs/edit/:id",
    exact: true,
    component: EditFAQ,
  },
  {
    type: RouteType.PRIVATE,
    path: "/offers/edit/:id",
    exact: true,
    component: EditOffer,
  },
  {
    type: RouteType.PRIVATE,
    path: "/settings",
    exact: true,
    component: ShellInformation,
  },
  {
    type: RouteType.PRIVATE,
    path: "/offers/notify",
    exact: true,
    component: SendNotification,
  },
];

export const Routes = () => {
  const { getLngPrefix } = useLanguageContext();
  const lngPrefix = getLngPrefix();
  return routes.map((route) => {
    const { path, type, ...rest } = route;

    if (type === RouteType.PRIVATE)
      return <PrivateRoute {...rest} path={lngPrefix + path} key={path} />;

    if (type === RouteType.RESTRICTED)
      return (
        <PublicRoute
          {...rest}
          path={lngPrefix + path}
          restricted={true}
          key={path}
        />
      );

    return <PublicRoute {...rest} path={lngPrefix + path} key={path} />;
  });
};
