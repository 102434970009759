import React from "react";
import FormLayout from "../../layouts/PageLayout";
import OfferForm from "../../components/forms/OfferForm/OfferForm";
import { useOfferFormik } from "../../components/forms/OfferForm/lib/useOfferFormik";
import API from "../../api/API";
import { useTranslation } from "react-i18next";
import { useLanguageContext } from "../../lib/context/LanguageContext/LanguageContext";

export const AddOffer = (props) => {
  const { t } = useTranslation();
  const { getLngPrefix } = useLanguageContext();
  const formik = useOfferFormik({
    onSubmit: async (values, formikHelpers) => {
      let formData = new FormData();

      formData.append("title", values.title);
      formData.append("content", values.content);
      formData.append("price", values.price);
      formData.append("offerImage", values.img);

      try {
        await API.offers.addOffer(formData);
        formikHelpers.resetForm({ values: { ...values } });
        props.history.push(`${getLngPrefix()}/offers`);
      } catch (e) {}
    },
  });
  return (
    <FormLayout title={t("addOffer")}>
      <OfferForm formik={formik} />
    </FormLayout>
  );
};
