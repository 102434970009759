import React, { useEffect, useState } from "react";
import ProductForm from "../../components/forms/ProductForm/ProductForm";
import { useProductFormik } from "../../components/forms/ProductForm/lib/useProductFormik";
import API from "../../api/API";
import HandleError from "../../components/shared/HandleError/HandleError";
import HandleLoading from "../../components/shared/HandleLoading/HandleLoading";
import FormLayout from "../../layouts/PageLayout";
import { useTranslation } from "react-i18next";
import { useRouter } from "../../lib/hooks/useRouter";
import { useLanguageContext } from "../../lib/context/LanguageContext/LanguageContext";
import FormSkeleton from "../../components/forms/Form.skeleton";

export const EditProduct = (props) => {
  const { getLngPrefix } = useLanguageContext();
  const router = useRouter();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [initialValues, setInitialValues] = useState();
  const [categories, setCategories] = useState([]);
  const { t, i18n } = useTranslation();
  const { id } = props.match.params;

  useEffect(() => {
    API.category
      .getAllCategories()
      .then((data) => {
        setCategories(
          data.map((category) => ({
            label: category.category_info.name,
            id: category._id,
          }))
        );
        return data;
      })
      .then((catego) => {
        API.products
          .getProduct(id)
          .then((data) => {
            const category = catego.find(
              (category) => category._id === data.categoryId
            );
            setInitialValues({
              name: data.product.name,
              description: data.product.description,
              price: data.price,
              img: data.productImage,
              category: {
                label: category?.category_info.name,
                id: category?._id,
              },
            });
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            setError(err.toString());
          });
      });
  }, [id, i18n.language]);

  const formik = useProductFormik({
    initialValues,
    onSubmit: async (values, formikHelpers) => {
      let formData = new FormData();
      formData.append("name", values.name);
      formData.append("description", values.description);
      formData.append("productImage", values.img);
      formData.append("price", values.price);
      formData.append("categoryId", values.category.id);
      try {
        await API.products.updateProduct(id, formData);
        formikHelpers.resetForm({ values: { ...values } });
        router.history.push(`${getLngPrefix()}/products`);
      } catch (e) {}
    },
  });

  return (
    <HandleLoading loading={loading} loadingPlaceholder={<FormSkeleton />}>
      <HandleError error={error}>
        <FormLayout title={t("editProduct")}>
          <ProductForm formik={formik} categories={categories} editMode />
        </FormLayout>
      </HandleError>
    </HandleLoading>
  );
};
