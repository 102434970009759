import React from "react";
import CategoryForm from "../../components/forms/CategoryForm/CategoryForm";
import { useCategoryFormik } from "../../components/forms/CategoryForm/lib/useCategoryFormik";
import API from "../../api/API";
import FormLayout from "../../layouts/PageLayout";
import { useTranslation } from "react-i18next";
import { useLanguageContext } from "../../lib/context/LanguageContext/LanguageContext";

export const AddCategory = (props) => {
  const { t } = useTranslation();
  const { getLngPrefix } = useLanguageContext();
  const formik = useCategoryFormik({
    onSubmit: async (values, formikHelpers) => {
      let formData = new FormData();
      formData.append("name", values.name);
      formData.append("description", values.description);
      formData.append("categoryImage", values.img);
      formData.append("categoryIdFromShell", values.id);

      try {
        await API.category.addCategory(formData);
        formikHelpers.resetForm({ values: { ...values } });
        props.history.push(`${getLngPrefix()}/categories`);
      } catch (e) {}
    },
  });

  return (
    <FormLayout title={t("addCategory")}>
      <CategoryForm formik={formik} />
    </FormLayout>
  );
};
