import React, { useEffect, useState } from "react";
import Table from "../../shared/Table/Table";
import Button from "../../shared/Button/Button";
import API from "../../../api/API";
import { useConfirm } from "../../../lib/context/ConfirmContext/ConfirmContext";
import { useRouter } from "../../../lib/hooks/useRouter";

export const UsersTable = (props) => {
  const [users, setUsers] = useState([]);
  const router = useRouter();
  const { confirm } = useConfirm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    setLoading(true);
    API.user
      .getAllUsers()
      .then((data) => {
        setUsers(data);
        setLoading(false);
      })
      .catch((err) => {
        setError(err.toString());
        setLoading(false);
      });
  }, []);

  const handleDelete = (id) => {
    confirm().then(() => console.log(id));
  };

  const columns = [
    {
      key: "user_email",
      header: "Email",
      sortable: true,
    },
    {
      key: "user_phoneNumber",
      header: "Phone Number",
      sortable: true,
    },
    {
      key: "user_businessName",
      header: "Business Name",
      sortable: true,
    },
    {
      key: "user_businessNumber",
      header: "Business Number",
      sortable: true,
    },
    {
      key: "user_actions",
      header: "Actions",
    },
  ];

  const rows = users.map((user) => ({
    user_email: <span data-sort={user.email}>{user.email}</span>,
    user_phoneNumber: (
      <span data-sort={user.phoneNumber}>{user.phoneNumber}</span>
    ),
    user_businessName: (
      <span data-sort={user.businessName}>{user.businessName}</span>
    ),
    user_businessNumber: (
      <span data-sort={user.businessNumber}>{user.businessNumber}</span>
    ),
    user_actions: (
      <Button
        type="danger"
        label="Delete"
        onClick={() => handleDelete(user._id)}
      />
    ),
  }));

  return (
    <Table
      title="Users"
      columns={columns}
      rows={rows}
      loading={loading}
      error={error}
      button={
        <Button
          label="Add User"
          onClick={() => router.history.push("/users/add")}
        />
      }
    />
  );
};
