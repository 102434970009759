import React, { useEffect, useState } from "react";
import FormLayout from "../../layouts/PageLayout";
import { useOfferFormik } from "../../components/forms/OfferForm/lib/useOfferFormik";
import API from "../../api/API";
import HandleLoading from "../../components/shared/HandleLoading/HandleLoading";
import HandleError from "../../components/shared/HandleError/HandleError";
import OfferForm from "../../components/forms/OfferForm/OfferForm";
import { useLanguageContext } from "../../lib/context/LanguageContext/LanguageContext";
import { useRouter } from "../../lib/hooks/useRouter";
import FormSkeleton from "../../components/forms/Form.skeleton";
import { useTranslation } from "react-i18next";

export const EditOffer = (props) => {
  const { id } = props.match.params;
  const { t, i18n } = useTranslation();
  const router = useRouter();
  const { getLngPrefix } = useLanguageContext();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [initialValues, setInitialValues] = useState({});

  const formik = useOfferFormik({
    initialValues,
    onSubmit: async (values, formikHelpers) => {
      let formData = new FormData();

      formData.append("title", values.title);
      formData.append("content", values.content);
      formData.append("price", values.price);
      formData.append("offerImage", values.img);
      console.log("formData", formData);
      try {
        await API.offers.updateOffer(id, formData);
        formikHelpers.resetForm({ values: { ...values } });
        router.history.push(`${getLngPrefix()}/offers`);
      } catch (e) {}
    },
  });

  useEffect(() => {
    setLoading(true);
    API.offers
      .getOffer(id)
      .then((data) => {
        setInitialValues({
          title: data.offer.title,
          content: data.offer.content,
          price: data.offer.price,
          img: data.offerImage,
          translations: data.translations,
        });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setError(err.toString());
      });
  }, [id, i18n.language]);

  return (
    <HandleLoading loading={loading} loadingPlaceholder={<FormSkeleton />}>
      <HandleError error={error}>
        <FormLayout title={t("editOffer")}>
          <OfferForm formik={formik} editMode />
        </FormLayout>
      </HandleError>
    </HandleLoading>
  );
};
