import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

export const useOfferFormik = (props) => {
  const { t } = useTranslation();

  const offerSchema = Yup.object().shape({
    title: Yup.string().required(t("formValidations.title")),
    content: Yup.string().required(t("formValidations.content")),
    price: Yup.number()
      .required(t("formValidations.price"))
      .typeError(t("formValidations.priceValue")),
    img: Yup.string().required(t("formValidations.image")),
  });

  return useFormik({
    initialValues: {
      title: "",
      content: "",
      price: "",
      img: "",
      ...props.initialValues,
    },
    validationSchema: offerSchema,
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: async (values, formikHelpers) => {
      await props.onSubmit(values, formikHelpers);
    },
  });
};

useOfferFormik.propTypes = {
  props: PropTypes.shape({
    initialValues: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
  }),
};
