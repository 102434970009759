import React from "react";
import injectSheet from "react-jss";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

const FieldVersionInOtherLang = (props) => {
  const { translations, field, classes } = props;
  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  const otherLang = lang === "sq" ? "en" : "sq";
  const fieldInOtherLang = translations?.[otherLang]?.[field];
  const titleInCurrentLang = translations?.[lang]?.[field];

  if (titleInCurrentLang) {
    return <></>;
  }

  return (
    <span>
      <i className={classes.languageVersion}>
        {t("languageVersion", {
          language: lang === "sq" ? t("English") : t("Albanian"),
        })}
        <span>:</span>
      </i>
      <span
        dangerouslySetInnerHTML={{ __html: fieldInOtherLang }}
        className={classes.root}
      />
    </span>
  );
};

const styles = ({ palette }) => ({
  languageVersion: {
    color: palette.button.warning,
    "& span": {
      margin: "0 5px 0 1px",
    },
  },
});

export default injectSheet(styles)(FieldVersionInOtherLang);

FieldVersionInOtherLang.propTypes = {
  classes: PropTypes.object,
  translations: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired,
};
